import React, { useEffect } from 'react';
import "../css/TalonMobile.css"
import Navigation from "./navigation";
import Footer from '../footer';
import { Row, Container, ListGroupItem} from "react-bootstrap"
import Image from 'react-bootstrap/Image';
import ListGroup from 'react-bootstrap/ListGroup';
import Infobar from '../infobar';
import AOS from 'aos';
import "aos/dist/aos.css";

function TalonETAMobile() {
  useEffect(() => {
    AOS.init ({ duration: 1000 });
    document.title = 'ETA Mobile - Talon Systems';
}, []);
    return (
        <div>
        <Infobar />
        <Navigation />
        <Container>

        <Row className="mb-5 mt-3"></Row>
  
        <div className="row">
        <div className="col-md-4 mb-3 mt-3 d-none d-lg-block"><hr /></div>
        <div className="col-md-4 mb-3 mt-3 d-none d-lg-block"><h1>ETA MOBILE APP</h1></div>
        <div className="col-md-4 mb-3 mt-3 d-none d-lg-block"><hr /></div>
        <div className="col-md-12 mb-3 mt-3 d-lg-none"><h1>ETA MOBILE APP</h1></div> 
        </div>
        
        <div className="row align-middle mt-3 d-flex">


          <div className="col-md-4">
          <p className="text-center">
          <div className="mobileimage">
          <Image src="talonsystemsimages/etamobile.webp" rounded />
          </div>
          </p>
          <p className="text-center"><a href="https://apps.apple.com/us/app/talon-eta-mobile-2-0/id647922838" className="btn btn-primary btn-lg btn-block" role="button" aria-pressed="true">Apple App Store</a> <a href="https://play.google.com/store/apps/details?id=com.talonsystems.etapro2041&pli=1" className="btn btn-primary btn-lg btn-block" role="button" aria-pressed="true">Google PlayStore</a></p>
          
          <p className="text-start">With the growing number of mobile users, it's essential to have a mobile app to keep them up to date with what’s happening in ETA.
            Receiving a mobile push notification when your flight is changed or cancelled makes all the difference in the world! While ETA Mobile is useful for checking training progress, scheduling, and pilot log, it is not meant to be a replacement for the desktop version of ETA. The desktop version remains your official source for ETA information.
          </p>
          </div>

          <div className="col-md-1">
            {/* For spacing */}
          </div>


          <div className="col-md-7">
          <p className="text-start">  
            <ListGroup>
              <ListGroupItem>Daily schedule updated on your mobile device</ListGroupItem>
              <ListGroupItem>Keep in touch with your students and instructors</ListGroupItem>
              <ListGroup.Item>Push notifications for New ETA messages, new pending authorizations, Aircraft downed, Student Check Ride Failed, Schedule changes such as flight cancelled, Schedule posted for tomorrow, and more</ListGroup.Item>
              <ListGroup.Item>View more details about an activity in schedule</ListGroup.Item>
              <ListGroup.Item>View the grade sheet for a student in schedule</ListGroup.Item>
              <ListGroup.Item>View an instructor’s schedule</ListGroup.Item>
              <ListGroup.Item>View, approve, and deny pending authorization requests</ListGroup.Item>
              <ListGroup.Item>View student course map (stages, lessons, units, and line items)</ListGroup.Item>
              <ListGroup.Item>View and confirm FIFs (Flight Information Files)</ListGroup.Item>
              <ListGroup.Item>View your pilot log book</ListGroup.Item>
              <ListGroup.Item>In-App link to send email directly to Talon Systems for comments, suggestions, or complaints</ListGroup.Item>
              <ListGroup.Item>View more details about a student’s course plan and progress and much more</ListGroup.Item>
            </ListGroup>
         </p>
        </div>

        </div>

        </Container>
        <Footer />
        </div>

    )
  }
  
  export default TalonETAMobile;

