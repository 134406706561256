import React, { useEffect } from 'react';
import "../css/TalonETA.css"
import Navigation from "./navigation";
import Footer from '../footer';
import { Row, Container} from "react-bootstrap"
import Image from 'react-bootstrap/Image';
import ListGroup from 'react-bootstrap/ListGroup';
import Infobar from '../infobar';
import AOS from 'aos';
import "aos/dist/aos.css";


function TalonETACustomizable() {
  useEffect(() => {
    AOS.init ({ duration: 1000 });
    document.title = 'ETA Customizable - Talon Systems';
}, []);
    return (
<div>
<Infobar />
<Navigation />
<Container>

        <Row className="mb-5 mt-3"></Row>
  
        <div className="row boxes">
        <div className="col-md-2 mb-3 mt-3 d-none d-lg-block"><hr /></div>
        <div className="col-md-8 mb-3 mt-3 d-none d-lg-block"><h1>ETA - Flight Training Management Software</h1></div>
        <div className="col-md-2 mb-3 mt-3 d-none d-lg-block"><hr /></div>
        <div className="col-md-12 mb-3 mt-3 d-lg-none"><h1>ETA - Flight Training Management Software</h1></div> 
        </div>

        <div className="row boxes">
        <div className="col-md-3 mb-3"></div>
          <div className="col-md-6">

          <h2>Customizable</h2>
          <Image src="talonsystemsimages/etacustomizable.webp" rounded />
          <p className="text-start">ETA can be customized by the user for unique customer requirements!
          The definitions component provides the capability to define labels, pick lists or drop down menus, location operating rules and parameters such as date and time thresholds or durations, 
          and much more. Customers with multiple locations can configure the ETA system with different and unique system operating capabilities for each location.
        </p>
        </div>
        <div className="col-md-3 mb-3"></div>
        </div>

        <div className="row boxes">
        <div className="col-md-4">
        <ListGroup>
          <ListGroup.Item>Account Types</ListGroup.Item>
            <ListGroup.Item>Admin, Refresher and Rental Types</ListGroup.Item>
            <ListGroup.Item>Aircraft</ListGroup.Item>
            <ListGroup.Item>Charge Codes</ListGroup.Item>
            <ListGroup.Item>Class Types</ListGroup.Item>
            <ListGroup.Item>Classes</ListGroup.Item>
            <ListGroup.Item>Conditions</ListGroup.Item>
            <ListGroup.Item>Contracts</ListGroup.Item>
            <ListGroup.Item>Countries</ListGroup.Item>
            <ListGroup.Item>Course Minimums</ListGroup.Item>
            <ListGroup.Item>Course Types</ListGroup.Item>
            <ListGroup.Item>Crews</ListGroup.Item>
            <ListGroup.Item>Currencies</ListGroup.Item>
            <ListGroup.Item>Customer Reports</ListGroup.Item>
            <ListGroup.Item>Destinations</ListGroup.Item>
        </ListGroup>
        </div>

        <div className="col-md-4">
        <ListGroup>
            <ListGroup.Item>Flight Blocks</ListGroup.Item>
            <ListGroup.Item>Flight Information Files</ListGroup.Item>
            <ListGroup.Item>Grading Standards – List</ListGroup.Item>
            <ListGroup.Item>Groundings – List</ListGroup.Item>
            <ListGroup.Item>Home Page Links</ListGroup.Item>
            <ListGroup.Item>Line Item Groups</ListGroup.Item>
            <ListGroup.Item>Line Items Definition</ListGroup.Item>
            <ListGroup.Item>Locations</ListGroup.Item>
            <ListGroup.Item>Login Alerts</ListGroup.Item>
            <ListGroup.Item>Merchandise</ListGroup.Item>
            <ListGroup.Item>Merchandise Categories</ListGroup.Item>
            <ListGroup.Item>Miscellaneous Activities</ListGroup.Item>
            <ListGroup.Item>Parking Spots</ListGroup.Item>
            <ListGroup.Item>Payment Method – List</ListGroup.Item>
            <ListGroup.Item>Payroll Periods</ListGroup.Item>
        </ListGroup>
        </div>

        <div className="col-md-4">
        <ListGroup>
            <ListGroup.Item>Person Roles</ListGroup.Item>
            <ListGroup.Item>Person Subtypes</ListGroup.Item>
            <ListGroup.Item>Person Titles</ListGroup.Item>
            <ListGroup.Item>Qualifications</ListGroup.Item>
            <ListGroup.Item>Reasons</ListGroup.Item>
            <ListGroup.Item>Registration Terms</ListGroup.Item>
            <ListGroup.Item>Resource Configurations</ListGroup.Item>
            <ListGroup.Item>Site List</ListGroup.Item>
            <ListGroup.Item>States</ListGroup.Item>
            <ListGroup.Item>Student Training Calendars</ListGroup.Item>
            <ListGroup.Item>Teams</ListGroup.Item>
            <ListGroup.Item>User Field Labels</ListGroup.Item>
            <ListGroup.Item>User type Display Names</ListGroup.Item>
        </ListGroup>
        </div>
        </div>
        </Container>
        <Footer />
        </div>
    )
  }
  
  export default TalonETACustomizable;

