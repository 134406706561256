import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Box2,
  Heading,
} from "./infobarStyles";
import "bootstrap/dist/css/bootstrap.css"; 
import { Row } from 'react-bootstrap';
// import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
// import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';
// import Navigation from "./pages/navigation";
import AOS from 'aos';
import "aos/dist/aos.css";



const Infobar = () => {
  useEffect(() => {
    AOS.init ({ duration: 2000 });
}, []);

    return (
      <Box2>

          <Row>
          <div data-aos="fade" className="col-md-12 mb-3 align-middle d-lg-none"><Heading>
          
          {/* <Navbar>
        <Navbar.Toggle aria-controls="navbarNav" />
        <Navbar.Collapse id="navbarNav">
          <Nav variant="underline" className="mx-auto">
            <LinkContainer to="/">
              <Nav.Link>Home</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/aboutus">
              <Nav.Link>About Us</Nav.Link>
            </LinkContainer>
            <NavDropdown  title="Our Products" id="basic-nav-dropdown">
                <NavDropdown.Item href="taloneta">TalonETA</NavDropdown.Item>
                <NavDropdown.Item href="talonrms">TalonRMS</NavDropdown.Item>
                <NavDropdown.Item href="talonsmart">TalonSMART</NavDropdown.Item>
            </NavDropdown>
            <LinkContainer to="/faq">
              <Nav.Link>FAQ</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/testimonials">
              <Nav.Link>Testimonials</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/contactus">
              <Nav.Link>Contact Us</Nav.Link>
            </LinkContainer>
          </Nav>
            </Navbar.Collapse>
      </Navbar> */}
          
          
          <a className="me-3" href="tel:+18172514321"><FontAwesomeIcon icon={faPhone} /></a>  |  <a className="mx-3" href="/contactus"><FontAwesomeIcon icon={faCalendarDays} /></a> | <a className="ms-3" href="mailto:info@talonsystems.com?subject=Request a Demo" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faEnvelope} /></a></Heading></div>
          {/* <div className="col-md-4 mb-3 align-middle d-md-none"><Heading><FontAwesomeIcon icon={faCalendarDays} /> <a href="/contactus">Request a Demo</a></Heading></div>   */}
          {/* <div className="col-md-6 mb-3 align-middle d-xl-none"><Heading><FontAwesomeIcon icon={faEnvelope} />  Info@talonsystems.com</Heading></div>  */}

          {/* <div className="col-md-12 mb-3 align-middle d-none d-sm-block"><Heading><FontAwesomeIcon icon={faPhone} /> (817) 251-4321</Heading> <Heading><FontAwesomeIcon icon={faCalendarDays} /> <a href="/contactus">Request a Demo</a></Heading><Heading><FontAwesomeIcon icon={faEnvelope} />  Info@talonsystems.com</Heading></div> */}
        </Row>

          <Row>
          <div data-aos="fade-right" className="col-md-4 mb-3 align-middle d-none d-lg-block d-xl-none"><Heading><a className="me-3" href="tel:+18172514321"><FontAwesomeIcon icon={faPhone} /> +1 (817) 251 - 4321</a></Heading></div>
          <div data-aos="fade" className="col-md-4 mb-3 align-middle d-none d-lg-block d-xl-none"><Heading><a className="mx-3" href="/contactus"><FontAwesomeIcon icon={faCalendarDays} /> Request a Demo</a></Heading></div>    
          <div data-aos="fade-left" className="col-md-4 mb-3 align-middle d-none d-lg-block d-xl-none"><Heading><span className="obfuscate"><a className="ms-3" href="mailto:info@talonsystems.com?subject=Request a Demo" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faEnvelope} />  Info@talonsystems<b>.talonsystems</b>.com</a></span></Heading></div>                 
          </Row>

          <Row>
          <div data-aos="fade-right" className="col-md-4 mb-3 align-middle d-none d-xl-block"><Heading><a className="me-3" href="tel:+18172514321">Speak to a Live Representative: <br /><FontAwesomeIcon icon={faPhone} /> +1 (817) 251 - 4321</a></Heading></div>
          <div data-aos="fade" className="col-md-4 mb-3 align-middle d-none d-xl-block mt-3"><Heading><a className="mx-3" href="/contactus"><FontAwesomeIcon icon={faCalendarDays} /> Request a Demo</a></Heading></div>    
          <div data-aos="fade-left" className="col-md-4 mb-3 align-middle d-none d-xl-block"><Heading><span className="obfuscate"><a className="ms-3" href="mailto:info@talonsystems.com?subject=Request a Demo" target="_blank" rel="noopener noreferrer">Ask Us a Question: <br /><FontAwesomeIcon icon={faEnvelope} />  Info@talonsystems<b>.talonsystems</b>.com</a></span></Heading></div>                 
          </Row>

  </Box2>
    );
}

export default Infobar;





