import React, { useEffect } from 'react';
import "../css/Testimonials.css"
import Navigation from "./navigation";
import Footer from '../footer';
import {Container, Row} from "react-bootstrap"
import Image from 'react-bootstrap/Image';
import Infobar from '../infobar';
import AOS from 'aos';
import "aos/dist/aos.css";

function TestimonialsPage() {
  useEffect(() => {
    AOS.init ({ duration: 1000 });
    document.title = 'Testimonials - Talon Systems';
}, []);
    return (
      <div>
      <Infobar />
      <Navigation />
      <Container>
      
      <div className="row mb-5 mt-3 boxes">
        <div className="col-md-1 mt-3 d-none d-lg-block"><hr /></div>
        <div className="col-md-10 mt-3"><h1>How Talon Helped This Flight School Return To Stability</h1></div>
        <div className="col-md-1 mt-3 d-none d-lg-block"><hr /></div>

        <div className="col-md-12 mb-1"><h3>King Schools hosts Envision Flight EDU-CON</h3></div>

                <Row className="mb-1 mt-3 ratio ratio-4x3">
                 <iframe src="https://www.youtube.com/embed/TanBcMGNYu4?si=1RQvdB07bLXDfoNa&amp;start=196" title="King Schools 2023 Envision Flight EDU-Con" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> 
                </Row>
                </div>

      {/* <div className="row">
        <div className="col-md-12 mb-3 mt-3 smartbackground bg-opacity-60 rounded"><h1><p className="text-center text-white">We love hearing stories about our customer's successes! <br />Share your experience working with Talon</p></h1></div>
        </div> */}
      
      <div className="row boxes">
        <div className="col-md-4 mb-3 d-none d-lg-block"><hr /></div>
        <div className="col-md-4 mb-3"><h1>TESTIMONIALS</h1></div>
        <div className="col-md-4 mb-3 d-none d-lg-block"><hr /></div>


        <div data-aos="fade" className="row" style={{justifyContent: 'center', alignItems: 'center'}}>
          <div className="col-md-6 mb-3"><Image style={{width: '100%'}} src="../images/testimonialheader.webp" rounded /></div>
        </div>

        <div data-aos="fade-up-right" className="row">
          <div className="col-md-6">
          <h2><b>University of Central Missouri</b></h2>
          <h3>LTC Tony Monetti, USAF, <br />
            (Ret) Assistant Dean of Aviation & Executive Director of Airport <br />
            Department of Aviation</h3>
          <p className="text-start text-wrap">
          Hi Jerry, Ed is a winner. You already know this but just wanted to relay my sincere appreciation for his hard work and excellent training this week while at UCM.
          My team is more aware of the capabilities of ETA and we remain a loyal customer due to a superior product and excellent customer service.
          Just wanted to relay my sincere thanks.
          </p>
          </div>

          <div className="col-md-6">
          <h2><b>North-Aire Aviation</b></h2>
          <h3>Greg Reverdiau<br />
          President<br /></h3><br /><br /><br />
          <p className="text-start text-wrap">
         I just wanted to let you know that I have been working with Eric for the last couple weeks on developing a QuickBooks import file and he has been outstanding. He was always very responsive with all the requests and I think we finally have a great product that you can actually reuse for other customers. This continues a tradition of great customer service from Talon Systems!
          </p>
          </div>
        </div>

        <div data-aos="fade-up-left" className="row">
          <div className="col-md-6">
          <h2><b>Mesa Air Group</b></h2>
          <h3>Mary Ann<br />
          Maintenance Administrative Assistant <br />
          </h3>
          <p className="text-start text-wrap">
          As always, Jerry, thank you for your time and input! You continue to maintain your enthusiasm for this program . . and I have personally been sold on it. 
          Most importantly, the support you provide is above and beyond anything any other company offers. 
          Thank you sincerely for that 
          </p>
          </div>

          <div className="col-md-6">
          <h2><b>LIBERTY UNIVERSITY</b></h2>
          <h3>JJ Bryant<br /></h3> <br />
          <p className="text-start text-wrap">
          Thank you so much for working with us! I realize that it is a lot of extra work on your part, and I want you to know that I appreciate it. I have been nothing but impressed with Talon Systems, it's product, and, most especially, Talon's employees. Thank you so much for all that you do in assisting flight training around the world, but most especially your assistance in helping our students to complete their training! <br />
          I am impressed with Talon Systems, and I gladly recommend your products to others that I come into contact with. It has been, and continues to be, ground-breaking in an industry of cheap imitators.
          </p>
          </div>
        </div>

        <div className="row">
          <div data-aos="fade-right" className="col-md-6">
          <h2><b>Scandinavian Aviation Academy</b></h2>
          <h3>Elena Voitik and Johan Eriksson<br />
          Schedulers<br />
          </h3>
          <p className="text-start text-wrap">
          Dear Sir,<br />
          On behalf of SAA Scheduling department, please, allow us to express our gratitude to Mr. Ed Hardick, who we've had the pleasure of meeting last week and who did a marvelous 
          job of expanding our Talon horizons!
          We were thoroughly impressed by the unique type of knowledge (aviation combined with computer technologies) 
          that Ed posses, as well as his human qualities - he is a generous (he shared a hotel issued cookie with us!) and intelligent person with a great sense of humor. 
          He is also a very loyal employee of yours - he was praising the company and its founder (namely, yourself Jerry) with utmost respect and admiration.
          We wish prosperity to you and your company! (Seems that, with employees like Ed, you have no other option as to grow and prosper.)
          </p>
          </div>

          <div data-aos="fade-left" className="col-md-6">
          <h2><b>Utah State University</b></h2>
          <h3>
          Sean E. Heiner <br />
          Chief Flight Instructor <br />
          Professional Pilot Program
          </h3> 
          <p className="text-start text-wrap">
          I just wanted to remind you of the amazing asset you have in (Jim). He has never let me down when I've needed some technical support from Talon Systems, Inc. He continues to amaze me!
          As you can see from the email messages below, I sent a request for a custom report to Jim last night after hours. He responded to my request last night and said he would get on it this morning. He is working on the report right now and he has already sent me an Excel file with all of the data I will need for my study. He saved me hours of data compilation for my study and has reduced my stress level significantly.
          I'm still very satisfied with our purchase of the ETA system! Thank you for the continued support from your company.
          </p>
          </div>
        </div>

        <div data-aos="fade-down-right" className="row">
          <div className="col-md-6">
          <h2><b>CAE Global Academy, Evora Portugal</b></h2>
          <h3>JC<br />
          Director of Maintenance<br />
          </h3>
          <p className="text-start text-wrap">
          I would like to inform you that we're finally 100% on RMS. We don't use any other paper or old process, we just use the RMS. Until the end of 2010 we'll continue printing the RMS Work Order and the RMS Event Checklist, but then after INAC trusts the system we plan to eliminate the paper forever in our Maintenance Processes.
          We didn't have an INAC audit yet, but I believe that we'll have success on the next one. We can show an "almost perfect" tool to manage and control an EASA 145 AMO and at the same time an EASA Part-M CAMO.
          To finish I would like to thank you for your help and support that gave us the opportunity to jump to a new and more efficient tool to manage and control our Maintenance Organization.
          </p>
          </div>

          <div className="col-md-6">
          <h2><b>LeTourneau University</b></h2>
          <h3>
             M.D.<br />
            Director of IT
          </h3> 
          <p className="text-start text-wrap">
          Your support worked with me yesterday and was incredibly helpful. He was able to answer all my questions, provided insight to things I might not have considered, and was not only thorough, but FAST. In less than a day, he had proposed a solution to this issue, mocked up the idea, and implemented it in a working use case on our end. That sort of efficiency is just unheard of in most of the technology products in which we deal with outside vendors. It's hard to convey in text medium how impressed I am and how much I appreciate the response from you guys. Simply incredible. <br /><br />
          Thanks again, <br /><br />
          MD
          </p>
          </div>
        </div>

        <div data-aos="fade-down-left" className="row">
          <div className="col-md-6">
          <h2><b>Moncton Flight College</b></h2>
          <h3>L.<br />
          Moncton NB Canada
          </h3>
          <p className="text-start text-wrap">
          Tomorrow is the day we go live with ETA at our new Location.
          But with your guys help over the last year and a half we feel very confident it will go well.
          You have probably heard that we are also setting up 2 more locations for our program as well.
          It's now hard to believe we could successfully manage a school like ours without ETA.<br /><br />
          Thanks, L.
          </p>
          </div>

          <div className="col-md-6">
          <h2><b>Aeroguard Flight School</b></h2>
          <h3>
          AW
          </h3> 
          <p className="text-start text-wrap">
          Thanks Jim! Like I said before Jim and his team are amazing. Anytime I call they take care of the issue right away. I just want to make sure that you know that.<br /><br />
        Thanks,<br />
        AW 
          </p>
          </div>
        </div>
        </div>



      </Container>
    <Footer />
    </div>
    )
  }
  
  export default TestimonialsPage;