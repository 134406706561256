import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import HomePage from './pages/Home';
import AboutUsPage from './pages/AboutUs';
import FAQPage from './pages/FAQ';
import ContactUsPage from './pages/ContactUs';
import TestimonialsPage from './pages/Testimonials';
import TalonETAPage from './pages/TalonETA';
import TalonETAMobile from './pages/TalonETAMobile';
import TalonRMSPage from './pages/TalonRMS';
import TalonSMARTPage from './pages/TalonSMART';
import TalonETAReports from './pages/TalonETAReports';
import TalonRMSReports from './pages/TalonRMSReports';
import TalonETACustomizable from './pages/TalonETACustomizable';
import TalonETAGuidedTour from './pages/TalonETAGuidedTour';
import ThankYouPage from './pages/ThankYou';
import Error404 from './pages/Error404';



const router = createBrowserRouter([
  { path: '/',  element: <HomePage /> },
  { path: '/aboutus',  element: <AboutUsPage /> },
  { path: '/faq',  element: <FAQPage /> },
  { path: '/contactus',  element: <ContactUsPage /> },
  { path: '/testimonials',  element: <TestimonialsPage /> },
  { path: '/taloneta',  element: <TalonETAPage /> },
  { path: '/talonrms',  element: <TalonRMSPage /> },
  { path: '/talonsmart',  element: <TalonSMARTPage /> },
  { path: '/talonetareports',  element: <TalonETAReports /> },
  { path: '/talonrmsreports',  element: <TalonRMSReports /> },
  { path: '/talonetacustomizable',  element: <TalonETACustomizable /> },
  { path: '/talonetaguidedtour',  element: <TalonETAGuidedTour /> },
  { path: '/talonetamobile',  element: <TalonETAMobile /> },
  { path: '/404',  element: <Error404 /> },
  { path: '/thankyou',  element: <ThankYouPage /> }
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;