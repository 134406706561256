import React, { useEffect } from 'react';
import "../css/TalonSMART.css"
import Navigation from "./navigation";
import Footer from '../footer';
import { Row, Container} from "react-bootstrap"
import Infobar from '../infobar';
import SmartCarousel from './SmartCarousel';
import ScreenshotCarousel from './ScreenshotCarousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloud } from '@fortawesome/free-solid-svg-icons';
import { faEarthAmericas } from '@fortawesome/free-solid-svg-icons';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';
import { faHandsHelping } from '@fortawesome/free-solid-svg-icons';
import AOS from 'aos';
import "aos/dist/aos.css";


function TalonSMARTPage() {
    useEffect(() => {
        AOS.init ({ duration: 1000 });
        document.title = 'SMART - Talon Systems';

    }, []);
    return (
<div>
<Infobar />
<Navigation />
<Container>

        <Row className="mb-5 mt-3"></Row>
  
        <div data-aos="fade" className="row boxes">
        <div className="col-md-4 mb-3 mt-3 d-none d-lg-block"><hr /></div>
        <div className="col-md-4 mb-3 mt-3 d-none d-lg-block"><h1>TalonSMART</h1></div>
        <div className="col-md-4 mb-3 mt-3 d-none d-lg-block"><hr /></div>
        <div className="col-md-12 mb-3 mt-3 d-lg-none"><h1>TalonSMART</h1></div> 

            <div className="col-md-12 mb-3"><h2><b>S</b>afety <b>M</b>anagement <b>A</b>nd <b>R</b>eporting <b>T</b>racker</h2></div>

        <div className="col-md-2"></div>
        <div className="col-md-8 smartbackground"><div className="carouselSize smartbackground"><SmartCarousel /></div></div>
        <div className="col-md-2"></div>
        </div>



        <div data-aos="fade-right" className="row mb-4 mt-5 boxes">
        <div className="col-md-4"><h2><FontAwesomeIcon icon={faCloud}   size="1x" /> ETA Interface <FontAwesomeIcon icon={faCloud}   size="1x" /></h2><p className="text-start text-wrap">Integration with Talon's ETA software
            If you use ETA, you can have a direct interface to TalonSMART. Much of the data in ETA such as names, addresses, tail numbers, etc. can be pre-filled when creating a new incident report!</p></div>

        <div className="col-md-4"><h2><FontAwesomeIcon icon={faEarthAmericas} size="1x" /> Universal <FontAwesomeIcon icon={faEarthAmericas} size="1x" /></h2><p className="text-start text-wrap">Works on everything!
            Take your iPad out to the scene of an accident. Manage the investigation from the field! Record eye witness reports in audio or video. Take pictures. Upload your media to your TalonSMART investigation from your PC or Mac.</p></div>

        <div className="col-md-4"><h2><FontAwesomeIcon icon={faClipboard}   size="1x" /> Risk Management <FontAwesomeIcon icon={faClipboard}   size="1x" /></h2><p className="text-start text-wrap">Manage risk controls
            User-defined hazards and risks. Assign a hazard with a risk. Then attach to an investigation. Create corrective actions. Assign due dates and responsibilities.</p></div>
        </div>

        <div data-aos="fade-left" className="row mb-5 mt-1 boxes">
        <div className="col-md-4"><h2><FontAwesomeIcon icon={faThumbsUp}   size="1x" /> Safety Assurance <FontAwesomeIcon icon={faThumbsUp}   size="1x" /></h2> <p className="text-start text-wrap">Evaluate the effectiveness of risk controls
            Corrective actions can be set to automatically become part of a risk's controls. Set audit and reminder dates for re-examining mitigation controls. TalonSMART sends you an email when it's time to re-evaluate!</p></div>
        <div className="col-md-4"><h2><FontAwesomeIcon icon={faLock}   size="1x" /> Secure Information <FontAwesomeIcon icon={faLock}   size="1x" /></h2><p className="text-start text-wrap">256 bit file encryption
            All data in TalonSMART is encrypted with the latest SSL technology. What's more, when an investigation is completed, you can decide what information to keep and what to destroy by automatic de-identification.</p></div>

        <div className="col-md-4"><h2><FontAwesomeIcon icon={faHandsHelping}   size="1x" /> Safety Promotion <FontAwesomeIcon icon={faHandsHelping}   size="1x" /></h2><p className="text-start text-wrap">Everyone has a role in promoting safety
            TalonSMART sends out emails that keep everyone in your organization informed of incidents, investigations, and frequent updates.</p></div>
        </div>

        <div data-aos="fade-up" className="row boxes">
            <div className="col-md-12 smartbackground h1yellow">
            <div className="carouselSize2">
            <ScreenshotCarousel />
            </div>
            </div>
            <p className="text-center mt-3"><a href="/contactus" className="btn btn-primary btn-lg btn-block" role="button" aria-pressed="true">Request a Live Demo!</a></p>
        </div>
        </Container>
        <Footer />
        </div>

    )
  }
  
  export default TalonSMARTPage;

