import React, { useEffect } from 'react';
import "../css/TalonETA.css"
import Navigation from "./navigation";
import Footer from '../footer';
import { Row, Container} from "react-bootstrap"
// import Image from 'react-bootstrap/Image';
import Infobar from '../infobar';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import AOS from 'aos';
import "aos/dist/aos.css";


function TalonETAGuidedTour() {
  useEffect(() => {
    AOS.init ({ duration: 1000 });
    document.title = 'ETA Guided Tour - Talon Systems';
}, []);
    return (
<div>
<Infobar />
<Navigation />
<Container>

        <Row className="mb-5 mt-3"></Row>
  
        <div className="row">
            <div className="col-md-4 mb-3 d-none d-lg-block"><hr /></div>
            <div className="col-md-4 mb-3 d-none d-lg-block"><h1>ETA - Guided Tour</h1></div>
            <div className="col-md-4 mb-3 d-none d-lg-block"><hr /></div>
        </div>

        <div className="row">
            <div className="col-md-12"><h2>A Miniature Demonstration</h2> 
            <p className="text-start text-wrap">
                  <iframe className="guidedtour" src="https://docs.google.com/presentation/d/e/2PACX-1vQ811YWQjAz_lp0nag6OZVtoP70MUGGdDAWQAOaxZVYOmLlkXRpkbRc3SLVrjVBxmPsZOC-Zz676T1z/embed?start=false&loop=false&delayms=60000" 
      frameborder="0" title="ETA Guided Tour" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
            </p>
        </div>
        </div>

        <div className="row">
        <div className="col-md-3 mb-3 d-none d-lg-block"><hr /></div>
        <div className="col-md-6 mb-3"><h1>Request a Full Demonstration!</h1></div>
        <div className="col-md-3 mb-3 d-none d-lg-block"><hr /></div>
        </div>

      <Form action="https://talonsystems.com/tracker/send.php" method="post">
      <InputGroup className="mb-3">
      <InputGroup.Text>Name: </InputGroup.Text>
      <Form.Control name="name" placeholder="Enter First and Last Name" aria-label="First name" />
    </InputGroup>

    <InputGroup className="mb-3">
      <InputGroup.Text>Email: </InputGroup.Text>
        <Form.Control type="email" placeholder="Enter Email" name="emailaddress" aria-label="Email"/>
      </InputGroup>

      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Ask away!</Form.Label>
        <Form.Control name="emailaddress" as="textarea" rows={3} />
      </Form.Group>
      <Button variant="primary" type="submit">
        Submit 
      </Button>
    </Form>

        </Container>
        <Footer />
        </div>

    )
  }
  
  export default TalonETAGuidedTour;

