import React, { useEffect } from 'react';
import "../css/TalonRMS.css"
import Navigation from "./navigation";
import Footer from '../footer';
import { Row, Container} from "react-bootstrap"
import Image from 'react-bootstrap/Image';
import ListGroup from 'react-bootstrap/ListGroup';
import Infobar from '../infobar';
import AOS from 'aos';
import "aos/dist/aos.css";


function TalonRMSPage() {

  useEffect(() => {
    AOS.init ({ duration: 1000 });
    document.title = 'RMS - Talon Systems';

}, []);

    return (
      <div>
      <Infobar />
<Navigation />
<Container>

        <Row className="mb-5 mt-3"></Row>
  
        <div data-aos="fade" className="row boxes">
        <div className="col-md-2 mb-3 mt-3 d-none d-lg-block"><hr /></div>
        <div className="col-md-8 mb-3 mt-3 d-none d-lg-block"><h1>RMS - Resource Maintenance System</h1></div>
        <div className="col-md-2 mb-3 mt-3 d-none d-lg-block"><hr /></div>
        <div className="col-md-12 mb-3 mt-3 d-lg-none"><h1>RMS - Resource Maintenance System</h1></div> 

        <div className="col-md-2 mb-3"></div>
        <div className="col-md-8 mb-3"><Image src="talonsystemsimages/rmsheader.webp" rounded /></div>
        <div className="col-md-2 mb-3"></div>

        <div className="col-md-12"> 
        <p className="text-start text-wrap">
        RMS, powered by Talon Systems, revolutionizes flight school operations by providing comprehensive control over fleet management, inventory, and maintenance. It seamlessly integrates with ETA’s Operations and Dispatch module, allowing administrators and maintenance personnel to manage reoccurring inspections and monitor the airworthiness of every aircraft. <br /><br />
        The system offers a detailed historical record for each aircraft component, along with features for parts shipping and receiving. Technicians gain instant access to crucial maintenance data such as airworthiness directives, service bulletins, parts manuals, and schematics. All relevant documents and images can be uploaded and linked to various records, enhancing operational efficiency. <br /><br />
        When dispatching, RMS intelligently prioritizes aircraft based on maintenance needs, suggesting aircraft that require sooner flights for inspections. Post-flight, pilots can report issues directly through the system, triggering maintenance actions that include comprehensive tracking of past work, part replacements, and labor time. <br /><br />
        Enhance management with dynamic alerts and intuitive reports on inventory levels and upcoming inspections, presented in a clear, visual format. Discover the full capabilities of RMS with a free online demo or an in-person visit from Talon Systems’ experts.<br /><br />
        </p></div>
        </div>
        
        <div data-aos="fade-down" className="row boxes">
        <div className="col-md-4 mb-3 mt-3 d-none d-lg-block"><hr /></div>
        <div className="col-md-4 mb-3 mt-3 d-none d-lg-block"><h1>Features</h1></div>
        <div className="col-md-4 mb-3 mt-3 d-none d-lg-block"><hr /></div>
        <div className="col-md-12 mb-3 mt-3 d-lg-none"><h1>Features</h1></div> 

        
        <h3>Discrepancy Management</h3>

        <p className="text-start">  
          <ListGroup>
           <ListGroup.Item>Tracks all discrepancies on multiple resource categories (aircraft, simulators, vehicles)</ListGroup.Item>
            <ListGroup.Item>Categorize discrepancies by type</ListGroup.Item>
            <ListGroup.Item>Automatic alerts, if desired, when discrepancy entered against a resource</ListGroup.Item>
            <ListGroup.Item>Tracks Squawks, Open Discrepancies, Deferred Discrepancies and Closed Discrepancies</ListGroup.Item>
            <ListGroup.Item>Create Work Order or assign discrepancy to existing work order</ListGroup.Item>
          </ListGroup>
          </p>
          </div>

        <div data-aos="fade-up" className="row boxes">
        <div className="col-md-6 mb-3 mt-3"><h3>Work Order Management</h3><Image src="talonsystemsimages/rmswom.webp" rounded /><p className="text-start mt-3">This image shows an RMS work Order. The drop down pick list near the top left called, Work Item, represents the problem or scheduled maintenance that is being worked. Every work order can have unlimited work items. You can request/fill parts in the section near the bottom left called Parts Used. The work performed or corrective actions are entered into the area with the 5 tabs. For this example, the Propulsion tab (Along with just about everything else in RMS, these tab names are defined by you in the Definitions module) shows what the tech did.</p>
        <ListGroup>
           <ListGroup.Item>Track all Open, Closed and in progress work orders</ListGroup.Item>
            <ListGroup.Item>Managers can electronically assign work orders to technicians</ListGroup.Item>
            <ListGroup.Item>Work Order clock in and clock out</ListGroup.Item>
            <ListGroup.Item>Automatic audit to ensure each discrepancy has associated corrective action</ListGroup.Item>
            <ListGroup.Item>Canned Corrective Action insert capability</ListGroup.Item>
            <ListGroup.Item>Work order can include recommended parts list</ListGroup.Item>
            <ListGroup.Item>Includes capability to pull parts from inventory</ListGroup.Item>
            <ListGroup.Item>Provides tracking of parts installed by work order</ListGroup.Item>
            <ListGroup.Item>Tracks parts removed by the work order</ListGroup.Item>
            <ListGroup.Item>Event checklists automatically made a part of the work order</ListGroup.Item>           
            <ListGroup.Item>Includes a “Pass Down” capability for carry-over work</ListGroup.Item>
            <ListGroup.Item>Technician, Inspected By, and Supervisor sign off audits</ListGroup.Item>
            <ListGroup.Item>Capability to generate “generic” work orders (work not associated with a resource, i.e., shop maintenance, inventory parts, or special projects)</ListGroup.Item>
          </ListGroup>
        </div>


        <div className="col-md-6 mb-3 mt-3"><h3>Event Management</h3><Image src="talonsystemsimages/rmsclock.webp" rounded />

        <ListGroup>
           <ListGroup.Item>Track all Open, Closed and in progress work orders</ListGroup.Item>
            <ListGroup.Item>Managers can electronically assign work orders to technicians</ListGroup.Item>
            <ListGroup.Item>Work Order clock in and clock out</ListGroup.Item>
            <ListGroup.Item>Automatic audit to ensure each discrepancy has associated corrective action</ListGroup.Item>
            <ListGroup.Item>Canned Corrective Action insert capability</ListGroup.Item>
            <ListGroup.Item>Work order can include recommended parts list</ListGroup.Item>
            <ListGroup.Item>Includes capability to pull parts from inventory</ListGroup.Item>
            <ListGroup.Item>Provides tracking of parts installed by work order</ListGroup.Item>
            <ListGroup.Item>Tracks parts removed by the work order</ListGroup.Item>
            <ListGroup.Item>Event checklists automatically made a part of the work order</ListGroup.Item>           
            <ListGroup.Item>Includes a “Pass Down” capability for carry-over work</ListGroup.Item>
            <ListGroup.Item>Technician, Inspected By, and Supervisor sign off audits</ListGroup.Item>
            <ListGroup.Item>Capability to generate “generic” work orders (work not associated with a resource, i.e., shop maintenance, inventory parts, or special projects)</ListGroup.Item>
          </ListGroup>
        </div>
        </div>
        


        <div data-aos="fade-down-right" className="row boxes">
        <div className="col-md-4 mb-3 mt-3 d-none d-lg-block"><hr /></div>
        <div className="col-md-4 mb-3 mt-3 d-none d-lg-block"><h1>Inventory Control</h1></div>
        <div className="col-md-4 mb-3 mt-3 d-none d-lg-block"><hr /></div>
        <div className="col-md-12 mb-3 mt-3 d-lg-none"><h1>Inventory Control</h1></div> 

        <div className="col-8 d-none d-lg-block"><Image src="talonsystemsimages/rmsipad.webp" rounded fluid /></div>
        <div className="col-12 d-lg-none"><Image src="talonsystemsimages/rmsipad.webp" rounded fluid /></div>
          
          <div className="col-4 d-none d-lg-block"> 

          <p className="text-start">  
          <ListGroup>
           <ListGroup.Item>Complete parts management capability</ListGroup.Item>
            <ListGroup.Item>User defined part types</ListGroup.Item>
            <ListGroup.Item>User defined kit types, kit components and kit assemblies</ListGroup.Item>
            <ListGroup.Item>Complete parts inventory with primary, alternate, substitute and superseded part numbers</ListGroup.Item>
            <ListGroup.Item>Provides real time parts quantities and cost and capability to set minimum, maximum and reorder levels</ListGroup.Item>
            <ListGroup.Item>Maintain manufacturer, supplier and alternate supplier information</ListGroup.Item>
            <ListGroup.Item>Detailed parts storage information down to individual bin level with “find” capability</ListGroup.Item>
            <ListGroup.Item>Detailed shipping and receiving information</ListGroup.Item>
            <ListGroup.Item>Warranty tracking on parts or components that have an associated warranty</ListGroup.Item>
            <ListGroup.Item>Warranty checking on removed parts</ListGroup.Item>
          </ListGroup>
          </p>
          </div>

          <div className="col-12 d-lg-none">          
          <p className="text-start">  
          <ListGroup>
           <ListGroup.Item>Complete parts management capability</ListGroup.Item>
            <ListGroup.Item>User defined part types</ListGroup.Item>
            <ListGroup.Item>User defined kit types, kit components and kit assemblies</ListGroup.Item>
            <ListGroup.Item>Complete parts inventory with primary, alternate, substitute and superseded part numbers</ListGroup.Item>
            <ListGroup.Item>Provides real time parts quantities and cost and capability to set minimum, maximum and reorder levels</ListGroup.Item>
            <ListGroup.Item>Maintain manufacturer, supplier and alternate supplier information</ListGroup.Item>
            <ListGroup.Item>Detailed parts storage information down to individual bin level with “find” capability</ListGroup.Item>
            <ListGroup.Item>Detailed shipping and receiving information</ListGroup.Item>
            <ListGroup.Item>Warranty tracking on parts or components that have an associated warranty</ListGroup.Item>
            <ListGroup.Item>Warranty checking on removed parts</ListGroup.Item>
          </ListGroup>
          </p></div>

        </div>



        
        <div data-aos="fade-down-left" className="row boxes">
          <div className="col-md-6"><h2>Corrective Actions</h2><Image src="talonsystemsimages/rmsdeskcomp.webp" rounded /><p className="text-start">  
          <ListGroup>
            <ListGroup.Item>All work order discrepancies require a corrective action entry before a work order can be closed</ListGroup.Item>
            <ListGroup.Item>Corrective actions can be pre-defined to simplify and ease technician input for standard and/or routine maintenance actions</ListGroup.Item>
            <ListGroup.Item>Corrective actions are entered into the applicable resource component category, for example, airframe, propulsion, avionics, or propeller</ListGroup.Item>
          </ListGroup>
          </p></div>
          <div className="col-md-6"><h2>Shipping and Receiving</h2><Image src="talonsystemsimages/rmsbox.webp" rounded /><p className="text-start">
        <ListGroup>
          <ListGroup.Item>Complete parts receiving capability</ListGroup.Item>
          <ListGroup.Item>Tracks parts that are on order and provides flags when due dates are not met</ListGroup.Item>
          <ListGroup.Item>Provides complete billing information including supplier, cost, tax, insurance, and shipping data</ListGroup.Item>
          <ListGroup.Item>Automatic flag when part for an open work order awaiting part is received.</ListGroup.Item>
          <ListGroup.Item>Tracks parts in shipping status (parts being sent out)</ListGroup.Item>
          <ListGroup.Item>Tracks shipping information to include, shipper, cost, pick-up date, tracking number and billing data</ListGroup.Item>
        </ListGroup>
        </p></div>
        </div>

        <div data-aos="fade-left" className="row boxes">
        <div className="col-md-12 mb-3"><h2>Tool Management</h2></div>

        <div className="col-md-6 mb-3"> <Image src="talonsystemsimages/rmstool.webp" rounded /></div>
        <div className="col-md-6 mb-3">
        <ListGroup>
          <ListGroup.Item>Manage all serialized tools and/or tools that have a periodic calibration requirement</ListGroup.Item>
          <ListGroup.Item>Manage tools by make, type, model, serial number and control number</ListGroup.Item>
          <ListGroup.Item>Real time current status of the tool (Failed, Passed, Not Tested)</ListGroup.Item>
        </ListGroup></div>
        </div>

        <div data-aos="fade-right" className="row boxes">
          <div className="col-md-6"><h2>Technician Certification</h2><Image src="talonsystemsimages/rmstechcert.webp" rounded />
          <p className="text-start">  
          <ListGroup>
          <ListGroup.Item>Define and track technician certifications</ListGroup.Item>
          <ListGroup.Item>Certifications can be set as a one time award or given an expiration time (days or months)</ListGroup.Item>
          <ListGroup.Item>Technicians can track own Certification currencies from a single button on their RMS Home Page</ListGroup.Item>
          <ListGroup.Item>Managers can easily monitor Certification currencies directly through RMS or through RMS report function</ListGroup.Item>
          </ListGroup>
          </p></div>

          <div className="col-md-6"><h2>Management Features</h2><Image src="talonsystemsimages/rmschart.webp" rounded />
          <p className="text-start">
          <ListGroup>
          <ListGroup.Item>Search through your massive history of work orders in the blink of an eye</ListGroup.Item>
          <ListGroup.Item>Maintains history of all work orders (Open, Closed, Cancelled) for reference and analysis.</ListGroup.Item>
          <ListGroup.Item>Management area Includes features for managing technician workload</ListGroup.Item>
          <ListGroup.Item>Capability to manage all work orders</ListGroup.Item>
          <ListGroup.Item>Captures technician work order hours and provides capability to allocate to Payroll Periods</ListGroup.Item>
          <ListGroup.Item>Provides capability to manage technician work order time</ListGroup.Item>
          </ListGroup>
          <br />
          
          <ListGroup>
          <ListGroup.Item>Provides maintenance managers automatic alerts for the following:</ListGroup.Item>
          <ListGroup.Item>  Event threshold and duration is reached</ListGroup.Item>
          <ListGroup.Item>  Engine/Propeller TBO threshold and TBO reached</ListGroup.Item>
          <ListGroup.Item>  Serialized part TBO within xxxx hours and overdue</ListGroup.Item>
          <ListGroup.Item>  Part warranty within xxxx days of expiration</ListGroup.Item>
          <ListGroup.Item>  Part shelf life with xxxx days of expiration</ListGroup.Item>
          <ListGroup.Item>  Work order created and clock time exceeds xxxx hours</ListGroup.Item>
          <ListGroup.Item>  Resource downed via a squawk</ListGroup.Item>
          </ListGroup>
        </p></div>
        </div>

        <div data-aos="fade" className="row boxes">
        <div className="col-md-4 mb-3 mt-3 d-none d-lg-block"><hr /></div>
        <div className="col-md-4 mb-3 mt-3 d-none d-lg-block"><h1>RMS Reports</h1></div>
        <div className="col-md-4 mb-3 mt-3 d-none d-lg-block"><hr /></div>
        <div className="col-md-12 mb-3 mt-3 d-lg-none"><h1>RMS Reports</h1></div> 

        

        <div className="col-md-6 mb-3"><Image src="talonsystemsimages/rmsreport.webp" rounded /></div>
        <div className="col-md-6 mb-3">
        <p className="text-start text-wrap">RMS, powered by Talon Systems, offers an extensive array of reporting capabilities, providing unparalleled insights into every aspect of flight school management. From detailed analyses of aircraft engine and propeller hours per month to comprehensive summaries of aircraft status and discrepancies, RMS empowers users with precise data for informed decision-making. With reports covering certifications, inventory management, customer accounts, personnel details, and work orders, RMS ensures thorough visibility into operations. Whether it's tracking aircraft maintenance schedules, monitoring inventory levels, or managing personnel certifications, RMS delivers actionable insights to optimize efficiency and ensure regulatory compliance.</p>

        <p className="text-center"><a href="/talonrmsreports" className="btn btn-primary btn-lg btn-block" role="button" aria-pressed="true">Learn more about TalonRMS Reports!</a></p>


        </div>
        </div>



        </Container>
        <Footer />
        </div>

    )
  }
  
  export default TalonRMSPage;