import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Row, Col, Nav} from "react-bootstrap"
import { LinkContainer } from 'react-router-bootstrap';
import "./css/Footer.css"
import AOS from 'aos';
import "aos/dist/aos.css";

const Footer = () => {
  useEffect(() => {
    AOS.init ({ duration: 1000 });
}, []);
    return (

<div>
    <Row data-aos="fade-up" className="p-3 mt-2 footerbackgroundcolor bg-gradient mx-auto text-center">
        <Col>
            <h2 className="text-white my-2">About Us</h2>
            <LinkContainer to="/aboutus">
              <Nav.Link className="my-2 text-white display-6">Our Company</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/testimonials">
              <Nav.Link className="my-2 text-white">Testimonials</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/faq">
              <Nav.Link className="my-2 text-white">FAQ</Nav.Link>
            </LinkContainer>
        </Col>
        <Col>
            <h2 className="text-white my-2">Explore</h2>
            <LinkContainer to="/taloneta">
              <Nav.Link className="my-2 text-white">TalonETA</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/talonrms">
              <Nav.Link className="my-2 text-white">TalonRMS</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/talonsmart">
              <Nav.Link className="my-2 text-white">TalonSMART</Nav.Link>
            </LinkContainer>
        </Col>
        <Col>
            <h2 className="text-white my-2">Ask Us</h2>
            <LinkContainer to="/contactus">
              <Nav.Link className="my-2 text-white">Contact Us Form</Nav.Link>
            </LinkContainer>
              <Nav.Link className="my-2 text-white">Phone: +1 817-251-4321</Nav.Link>
              <Nav.Link className="my-2 text-white">info@talonsystems.com</Nav.Link>
        </Col>
    </Row>

    <Row className="p-3  footerbackgroundcolor  mx-auto text-center">
    <div className="col-md-12 text-white text-center">Copyright &copy; 2001-2024 All rights reserved by TALON SYSTEMS, LLC</div>
    </Row>

</div>

    );
}

export default Footer;





