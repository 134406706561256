import React, { useEffect } from 'react';
import { Container, Image } from "react-bootstrap"
import "../css/AboutUs.css"
import Navigation from "./navigation";
import Footer from '../footer';
import Infobar from '../infobar';
import AOS from 'aos';
import "aos/dist/aos.css";

function AboutUsPage() {
    useEffect(() => {
        AOS.init ({ duration: 1000 });
        document.title = 'About Us - Talon Systems';
    }, []);
    return (
        <div>
        <Infobar />
        <Navigation />
        <Container>

                <div data-aos="fade-left" class="row align-middle mt-3 d-flex align-items-center boxes">
                <div class="col-md-6 mb-3"> <Image src="talonsystemsimages/aboutusbooth.jpg" rounded /></div>
                <div class="col-md-6 mb-3">
                    <h2>Our Company</h2>
                    <p class="text-start">Talon Systems, LLC was started in May of 2001. Our hallmark product is an Internet based flight training management system titled ETA (Education and Training Administration) that supports many thousands of users and thousands of flight training activities per day. ETA system functions and features include Curriculum Management, Personnel Management, Training Resources Management, Resource Planning, Scheduling, Student Flight Training Management, Flight Dispatch, Flight Following, Training Records, Cashier, Instructor Payroll. Talon Systems also developed an internet based Resource Maintenance System (RMS) that can be used as a stand-alone system for managing all maintenance actions and parts inventory for training resources or can be fully integrated with ETA operations and dispatch functions.
                    <br />
                    </p>
                    </div>
                </div>

                <div data-aos="fade-right" class="row align-middle d-flex align-items-center boxes">
                <div class="col-md-6 mb-3 d-none d-md-block">
                    <h2>Our Services</h2>
                    <p class="text-start">The combination of ETA for flight training management and an integrated RMS for maintenance management offers flight schools a single affordable solution for their entire flight training operation. Talon Systems is a small business that has over 70 years combined experience doing business with the U.S. Government as well as commercial and educational entities.   Our personnel have experience working with multiple defense contractors and components of the United States Department of Defense. We also deal with Department Heads, Program Managers, and Chief Pilots of university, college and commercial flight training operations. Talon Systems is a member of the University Aviation Association and the Aviation Accreditation Board International.  <br /></p>
                    <p class="text-center"><a href="/contactus" class="btn btn-primary btn-lg btn-block" role="button" aria-pressed="true">Request a brief Demo!</a></p>
                    </div>
                    <div class="col-md-6 mb-3 d-none d-md-block"><Image src="talonsystemsimages/aboutusdeskchair.webp" rounded /></div>
                </div>

                <div class="row align-middle d-flex align-items-center">
                <div class="col-md-6 mb-3 d-md-none"><Image src="talonsystemsimages/aboutusdeskchair.webp" rounded /></div>
                <div class="col-md-6 mb-3 d-md-none">
                    <h2>Our Services</h2>
                    <p class="text-start">The combination of ETA for flight training management and an integrated RMS for maintenance management offers flight schools a single affordable solution for their entire flight training operation. Talon Systems is a small business that has over 70 years combined experience doing business with the U.S. Government as well as commercial and educational entities.   Our personnel have experience working with multiple defense contractors and components of the United States Department of Defense. We also deal with Department Heads, Program Managers, and Chief Pilots of university, college and commercial flight training operations. Talon Systems is a member of the University Aviation Association and the Aviation Accreditation Board International.  <br /></p>
                    <p class="text-center"><a href="/contactus" class="btn btn-primary btn-lg btn-block" role="button" aria-pressed="true">Request a brief Demo!</a></p>
                    </div>
                </div>

                <div data-aos="fade-left" class="row align-middle d-flex align-items-center boxes">
                <div class="col-md-6 mb-3"><Image src="talonsystemsimages/aboutusbench.webp" rounded /></div>
                <div class="col-md-6 mb-3">
                    <h2>Time-Tested Experience since 2001</h2>
                    <p class="text-start">Let Talon Systems evaluate your training and/or resource maintenance programs and provide time-tested and proven methods for improvement. The principals at Talon Systems have over 40 years experience in flight training coupled with a combined experience of over 80 years in the software industry, with the majority of that time dedicated to developing training management solutions. Talon Systems’ products reflect a dedication to an integrated development environment that includes the customer. This approach results in solutions to customer requirements rather then theoretical misconceptions that don’t meet the customer’s  requirements.<br /></p>
                    <p class="text-center"><a href="/contactus" class="btn btn-primary btn-lg btn-block" role="button" aria-pressed="true">Request More Information!</a></p>
                    </div>
                </div>

                </Container>
                <Footer />
                
                </div>
            );
           
          }
  
  export default AboutUsPage;