import React, { useEffect } from 'react';
import "../css/FAQ.css"
import Navigation from "./navigation";
import Footer from '../footer';
import { Container } from "react-bootstrap"
import Accordion from 'react-bootstrap/Accordion';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import AOS from 'aos';
import "aos/dist/aos.css";
// import Infobar from '../infobar';


function FAQPage() {
  useEffect(() => {
    AOS.init ({ duration: 1000 });
    document.title = 'FAQ - Talon Systems';
}, []);
    return (
      <div>

      <Navigation />
      <Container>

      <div data-aos="fade-up-right" className="row boxes">
        <div className="col-md-3 mb-3 d-none d-lg-block"><hr /></div>
        <div className="col-md-6 mb-3"><h1>Frequently Asked Questions</h1></div>
        <div className="col-md-3 mb-3 d-none d-lg-block"><hr /></div>
        


      <Accordion flush>
      <Accordion.Item eventKey="0">
        <Accordion.Header>What is ETA?</Accordion.Header>
        <Accordion.Body>
        ETA, or Education & Training Administration, is a 100% web-based training management system designed, by Talon Systems, specifically for the management of flying training. It is a system, accessed through a web browser, which offers training programs unprecedented tools for managing curriculum, security, student records, resources, flight following/operations, and scheduling. 
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Why is Talon the best?</Accordion.Header>
        <Accordion.Body>
        Talon is the best software available for managing your flying training, maintenance, and safety program because it makes management more efficient, 
        increases management effectiveness and reduces risk. 
        <br />
        <br />
        <b>Efficiency</b>
        <br />
        Because the system is totally Internet based, there is no cost for loading software on individual computers. Hardware costs are reduced because the system can be accessed and used 
        with average capability PC computers with any kind of Internet access including regular dial-up. Additionally, software update costs and time are greatly reduced because 
        updates/changes/modifications are made quickly in one place rather than installing changes on multiple computers.
        <br />
        <br />

        <b>Effectiveness</b>
        <br />
        Managers can base decisions on readily and rapidly available accurate, real time data and all users of the systems are always using the same current up to date data. It ensures that all 
        key personnel are on the same sheet of music and are making decisions based on having the “Big Picture”.
        <br />
        <br />

        <b>Risk Reduction</b>
        <br />
        Talon provides numerous “behind the scenes” cross checks to ensure the right student is scheduled with the right instructor on the right lesson with the right training resource at the 
        right time. ETA Scheduling and Operations functions also ensure the instructor and student are available and have the necessary qualifications and have met applicable currency requirements 
        to safely perform the lesson.
        </Accordion.Body>
      </Accordion.Item>


   
      <Accordion.Item eventKey="2">
        <Accordion.Header>
        My organization has multiple locations. Can ETA support all locations while maintaining a single database?
        </Accordion.Header>
        <Accordion.Body>
        YES. ETA is a 100% web-based solution that utilizes a single database. Your organization can view vital information from all locations in an instant! There is no extra cost for multiple locations!
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="3">
        <Accordion.Header>
        Can ETA track instructor and student requirements?
	    </Accordion.Header>
        <Accordion.Body>
        YES. ETA allows user defined currencies and qualifications that are tracked throughout the system and can be tied to aircraft or course materials such as units or lessons. 
        ETA provides real-time progress data for student performance. Am I behind in my training? Have I spent more than ETA initially projected? 
        In addition, ETA provides managers with instructor performance data such as pass rates for assigned students as well as currencies and qualifications such as Medical, CFI, etc. 
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="4">
        <Accordion.Header>
        Does ETA provide any helpful charts or graphs?
	</Accordion.Header>
        <Accordion.Body>
        YES. New graphs are being added to ETA all the time! We have just under 300 reports for all of your training management needs. 
        On the off chance we don't have what you need you can request us to create a custom report tailored to your school's needs.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="5">
        <Accordion.Header>
        How does ETA interface with existing external systems?
	</Accordion.Header>
        <Accordion.Body>
        ETA has an API, which allows it to exchange data with external systems such as Payroll, Registrar Systems, Accounting, and any other application that allows you to visualize or analyze data.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="6">
        <Accordion.Header>
        Is ETA AICC Compliant?
	</Accordion.Header>
        <Accordion.Body>
        ETA is designed to be interfaced with CBT systems and can be integrated using the SCORM 1.1 OR 1.2 model. 
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="7">
        <Accordion.Header>
        How long does it take to get ETA up and running?
	      </Accordion.Header>
        <Accordion.Body>
        This answer is different for every organization. We have had a flight school completely up and running using ETA in the span of 7 days! 
        However, most schools require at least 2 to 3 weeks preparation before going live with ETA. 
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="8">
        <Accordion.Header>
        What kind of training is available once I purchase ETA?
	      </Accordion.Header>
        <Accordion.Body>
        Once you purchase ETA, Talon Systems provides on-site training and initial implementation support for a flat fee.<br />

        - Assistance with configuring ETA to best meet your particular needs. <br />
        - Setting up curriculum and other data such as personnel, resources, security, etc.<br />
        - Talon Systems also is on-site for your initial go-live day(s). We want to make sure you are successful with ETA so we provide side-by-side, on-site assistance. 
        This includes meeting and working with scheduling, accounting, operations/dispatch, curriculum developers, and management personnel to get things off on the right foot.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="9">
        <Accordion.Header>
        How is ETA “Real-Time”?
	      </Accordion.Header>
        <Accordion.Body>
        ETA collects data as it is happening and that data is immediately available to all applicable users of the systems. 
        Additionally, ETA provides real-time data for resource and personnel availability, flight following, scheduling etc. 
        For example: When the schedule is altered, an email can be sent to all affected, outlining the new schedule. 
        Also, ETA can be set up to send an alphanumeric message to all your devices. ETA mobile is the Apple/Android app that sends push notifications to keep you up to date. 
        You can receive push notifications about schedule changes, cancellations, student failed check rides, aircraft downed, ETA messages, “the schedule has been posted”, and so much more!
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="10">
        <Accordion.Header>
        Is ETA user-configurable?
	      </Accordion.Header>
        <Accordion.Body>
        Yes. ETA’s Definitions component provides the capability to define labels, pick lists or drop down menus, presentation items such as colors, 
        location parameters such as date and time thresholds or durations, and much more.
        </Accordion.Body>
      </Accordion.Item>
      </Accordion>  
      </div>

      <div data-aos="fade-up-left" className="row boxes">
        <div className="col-md-3 mb-3 mt-3 d-none d-lg-block"><hr /></div>
        <div className="col-md-6 mb-3 mt-3"><h1>Additional Questions?</h1></div>
        <div className="col-md-3 mb-3 mt-3 d-none d-lg-block"><hr /></div>



         <div className="col-md-12 mb-3">
    <a className="me-3 contactuslinks" href="tel:8172514321"><h3><FontAwesomeIcon icon={faPhone} shake size="1x"/><br /> <br />Speak to a Live Representative: <br />  (817) 251-4321</h3></a>
    </div>




        <div className="col-md-12 mb-3"><Form action="https://talonsystems.com/tracker/send.php" method="post">
      <InputGroup className="mb-3">

      <InputGroup.Text>Name: </InputGroup.Text>
      <Form.Control name="name" placeholder="Enter First and Last Name" aria-label="First name" />
    </InputGroup>

    <InputGroup className="mb-3">
      <InputGroup.Text>Email: </InputGroup.Text>
      <Form.Control type="email" placeholder="Enter Email" name="emailaddress" aria-label="Email"/>
      </InputGroup>

      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">

        <Form.Control name="emailaddress" placeholder="Tell us about what you are looking for us to be able to help you with. What are your problems? How many students and aircraft do you have?" as="textarea" rows={5} />
      </Form.Group>
      <Button variant="primary" type="submit">
        Submit 
      </Button>
    </Form>
    </div>
        </div>

    </Container>
    <Footer />
    </div>
    )
  }
  
  export default FAQPage;