import React, { useEffect } from 'react';
import "../css/TalonETA.css"
import Navigation from "./navigation";
import Footer from '../footer';
import { Container} from "react-bootstrap"
import Image from 'react-bootstrap/Image';
import ListGroup from 'react-bootstrap/ListGroup';
import Infobar from '../infobar';
import AOS from 'aos';
import "aos/dist/aos.css";


function TalonETAReports() {
    useEffect(() => {
        AOS.init ({ duration: 1000 });
        document.title = 'ETA Reports - Talon Systems';
    }, []);
    return (
        <div>
        <Infobar />
        <Navigation />
        <Container>


  
        <div className="row boxes">
        <div className="col-md-4 mb-3 mt-3 d-none d-lg-block"><hr /></div>
        <div className="col-md-4 mb-3 mt-3 d-none d-lg-block"><h1>ETA Reports</h1></div>
        <div className="col-md-4 mb-3 mt-3 d-none d-lg-block"><hr /></div>
        <div className="col-md-12 mb-3 mt-3 d-lg-none"><h1>ETA Reports</h1></div> 

        <div className="col-md-4 mb-3 mt-3 d-none d-lg-block"></div>
        <div className="col-md-4 mb-3 mt-3 d-none d-lg-block"><h1>Automated Report Delivery</h1></div>
        <div className="col-md-4 mb-3 mt-3 d-none d-lg-block"></div>
        <div className="col-md-12 mb-3 mt-3 d-lg-none"><h1>Automated Report Delivery</h1></div> 

        <div className="col-12"> <p className="text-center text-wrap">ETA Report Delivery Automation allows the user to schedule any report for scheduled, automated recurring execution and delivery via email attachment or FTP (automated file upload) to the customer’s own server)</p></div>
        </div>

        <div className="row boxes">
        <div className="col-6"><h2>Report Criteria Selection Page</h2><Image src="https://img1.wsimg.com/isteam/ip/6c5ccc62-f3a4-4e8e-b05b-025b5906f0dd/IMG_2312.jpeg/:/cr=t:0%25,l:0%25,w:100%25,h:100%25/rs=w:600,cg:true" rounded /><p className="text-start text-wrap">Screen shot showing the ETA core report selection criteria page. If the current user has access to Delivery Automation, a button will be at the bottom left of this page. 
        Clicking Delivery Automation will allow the user to schedule automated, recurring delivery of this report based on the current selection criteria. If a date range is present, Delivery Automation will use the current date/time (when the report is automatically being executed) to calculate the start and stop dates. </p>
        </div>



        <div className="col-6"><h2>FTP Options</h2><Image src="https://img1.wsimg.com/isteam/ip/6c5ccc62-f3a4-4e8e-b05b-025b5906f0dd/reportDeliveryNew-714e119.png/:/rs=w:400,cg:true,m" rounded />
     <p className="text-start text-wrap">
        ETA – Report Delivery Automation
        Setting Up a New Automated Delivery
        Delivery Method – Reports can be delivered via email as an attachment or via FTP to a server.   
        Email Attachment Name: This is the name of the file to be attached to the email (or sent via FTP). In this example, a file would be sent to the FTP server called dailyFlightSchedLocationSchedule.pdf   
        Email Attachment Type: Automated Delivery can send reports in the following formats: PDF, EXCEL, RTF (MS WORD), HTML, and CSV   
        Run Report: The choices in this drop down are: Every day, Every Week, First day of every Month, Last day of every month   
        Report Run Time: You can chose any hour for the report to be generated and delivered. Selections in this drop down contain the start of every hour of the day, e.g. 00:00, 01:00, 02:00 etc.   
        Report Period: This drop down determines what dates to consider for running the report. (See image to the left for available selections)   
        Host Address: This is the FTP host address for delivery via FTP   
        Report Directory Name: This is the name of your report directory (optional)
        Username: Your FTP account username   Password: Your FTP account password   
        Active: You may decide to un-check Active to temporarily suspend automated delivery of a report. Re-checking Active will let the report be delivered upon it’s next scheduled delivery.  </p>
        </div>
        </div>

        <div className="row boxes">
        <div className="col-md-4 mb-3 mt-3 d-none d-lg-block"><hr /></div>
        <div className="col-md-4 mb-3 mt-3 d-none d-lg-block"><h1>ETA Reports List</h1></div>
        <div className="col-md-4 mb-3 mt-3 d-none d-lg-block"><hr /></div>
        <div className="col-md-12 mb-3 mt-3 d-lg-none"><h1>ETA Reports List</h1></div> 

        <div className="col-md-3 mb-3"></div>
        <div className="col-md-6 mb-3"><Image src="https://img1.wsimg.com/isteam/stock/242/:/cr=t:0%25,l:0%25,w:100%25,h:100%25/rs=w:400,cg:true" rounded /></div>
        <div className="col-md-3 mb-3"></div>

        <p className="text-start">Here is a partial list of some of the most popular ETA reports. This is a good way to get a better understanding of the type of information that’s collected during the daily use of ETA.</p>

        <div className="col-md-6 mb-3">
        <ListGroup>
        <ListGroup.Item>Admin / Security – Admin / Security Audit – List of changes to passwords, PINs, user type assignments and page permissions over a date range</ListGroup.Item>
        <ListGroup.Item>Admin / Security – Disabled Users Report – List of users with disabled accounts per location</ListGroup.Item>
        <ListGroup.Item>Admin / Security – No – Access Pages – List of pages that currently do not have any access privileges per location</ListGroup.Item>
        <ListGroup.Item>Admin / Security – User Access – List of users with active/inactive, last access date/time, number of page hits today and last week per location</ListGroup.Item>
        <ListGroup.Item>Admin / Security – User Current Active Sessions – List of users currently accessing the system per location</ListGroup.Item>
        <ListGroup.Item>Admin / Security – User Discrepancy – List of active users that do not have a pin, password or zero user types assigned per location</ListGroup.Item>
        <ListGroup.Item>Admin / Security – User Page Access – List of pages a person (user) has access to per person per location</ListGroup.Item>
        <ListGroup.Item>Admin / Security – User To User Type XRef – List of users with associated user types per location</ListGroup.Item>
        <ListGroup.Item>Admin / Security – User Type Assignment Count – List of user types with the number of users assigned to each per location</ListGroup.Item>
        <ListGroup.Item>Admin / Security – User Type To Page XRef – List of user types with associated page access permissions</ListGroup.Item>
        <ListGroup.Item>Admin / Security – User Type To User XRef – List of user types with associated users per location</ListGroup.Item>
        <ListGroup.Item>Cashier – Account Types Accounting – Total costs of activities for each account type over a date range with option to show details</ListGroup.Item>
        <ListGroup.Item>Cashier – Cashier Activities – List of Cashier Activities with payment amounts over a Date Range</ListGroup.Item>
        <ListGroup.Item>Cashier – Charge Codes Accounting – Summary of the Amount of Time / Money charged against each Charge Code over a Date Range per Location</ListGroup.Item>
        <ListGroup.Item>Cashier – Instructor Payroll – List of Instructor Course Activities, Admin Activities and Payroll Adjusts within a Date Range per Instructor per Location</ListGroup.Item>
        <ListGroup.Item>Cashier – Merchandise Category Transactions Totals – Transaction totals and averages per merchandise category and merchandise item over a date range</ListGroup.Item>
        <ListGroup.Item>Cashier – Merchandise Sales Details – List of Merchandise Sales Items Per Transactions Over a Date Range</ListGroup.Item>
        <ListGroup.Item>Cashier – Merchandise Sales Details With Payments – List of Merchandise Sales Items Per Transactions with Payments Over a Date Range</ListGroup.Item>
        <ListGroup.Item>Cashier – Merchandise Sales Summary – List of Merchandise Sales Transactions Over a Date Range</ListGroup.Item>
        <ListGroup.Item>Cashier – Merchandise Sales per Item – List of merchandise sales per merchandise item over a date range</ListGroup.Item>
        <ListGroup.Item>Cashier – Payment Methods Accounting – Activity Date – Summary of the Amount of Time / Money Charged against each Payment Method over a Date Range per Location</ListGroup.Item>
        <ListGroup.Item>Cashier – Payment Methods Accounting – Transaction Date – Summary of the Amount of Time / Money Charged against each Payment Method over a Date Range per Location</ListGroup.Item>
        <ListGroup.Item>Cashier – Payroll Period Accounting – List of Activities per Payroll Period per Location</ListGroup.Item>
        <ListGroup.Item>Cashier – Trial Balance – List of transactions from person debit accounts, cashier, and merchandise sales over a date range</ListGroup.Item>
        <ListGroup.Item>Course Catalogue – Course – Activity Type Performance – List of Activity Types with number of attempts and passed, failed, repeats, incomplete, planned durations and actual durations per Course, per Location</ListGroup.Item>
        <ListGroup.Item>Course Catalogue – Course List – List of Current Training Courses with number of Active / Completed students assigned to each</ListGroup.Item>
        <ListGroup.Item>Course Catalogue – Course Map – Course – Stages – Lessons – Units – Course Minimums, Line Items per Location per Course</ListGroup.Item>
        <ListGroup.Item>Course Catalogue – Course Minimums – List of Course Minimums with cumulative required and optioned durations per Stage per Course</ListGroup.Item>
        <ListGroup.Item>Course Catalogue – Course Registrations – List of student registrations per course</ListGroup.Item>
        <ListGroup.Item>Course Catalogue – Course Resource Type Hours – List of Resource Types with cumulative hours per Course per Location</ListGroup.Item>
        <ListGroup.Item>Course Catalogue – Course Specifications – Course – Stages – Lessons – Units – Line Items, Resource Types, Currencies, Quals, Res Configs, TCO Mins</ListGroup.Item>
        <ListGroup.Item>Course Catalogue – Course Specifications With Comments – Course – Stages – Lessons – Units – Line Items, Resource Types, Currencies, Quals, Res Configs, TCO Mins with Comments</ListGroup.Item>
        <ListGroup.Item>Course Catalogue – Course Training Forecast – Course Training Forecast per Course</ListGroup.Item>
        <ListGroup.Item>Course Catalogue – Course Training Forecast Analysis – Listing of Actual Student Course Completions with Planned Completion Date, Actual Completion Date, Days Difference per Current Training Course per Location over a date range</ListGroup.Item>
        <ListGroup.Item>Course Catalogue – Course Unit Performance Summary – List of Units with number of attempts and passed, failed, repeats, incomplete, planned durations and actual durations per Course, per Location</ListGroup.Item>
        <ListGroup.Item>Course Catalogue – Course Work Book – Grade Sheet per Unit per Lesson per Stage per Course</ListGroup.Item>
        <ListGroup.Item>Course Catalogue – Line Item Tasks List – List of Line Items with assigned Tasks from the Course Catalogue</ListGroup.Item>
        <ListGroup.Item>Course Catalogue – Tasks List – List of Tasks from the Course Catalogue</ListGroup.Item>
        <ListGroup.Item>Definitions – Account Type List – List of Account Types including number of students assigned to each</ListGroup.Item>
        <ListGroup.Item>Definitions – Account Types To Student XRef – List of Account Types (not obsolete) with names of Active Students assigned to each</ListGroup.Item>
        <ListGroup.Item>Definitions – Aircraft Experience List – List of Aircraft Experience including number of instructors assigned to each with hours</ListGroup.Item>
        <ListGroup.Item>Definitions – Charge Code List – List of Charge Codes including number of Contracts assigned to each</ListGroup.Item>
        <ListGroup.Item>Definitions – Charge Code To Contract XRef – List of Charge Codes (not obsolete) with the Contracts assigned</ListGroup.Item>
        <ListGroup.Item>Definitions – Contract Resource Type Rates – List of Contracts with associated Resource Type Rates</ListGroup.Item>
        <ListGroup.Item>Definitions – Contracts List – List of Contracts including number of active / completed students assigned to each</ListGroup.Item>
        <ListGroup.Item>Definitions – Contracts To Student XRef – List of Contracts (not obsolete) with names of Active Students assigned to each</ListGroup.Item>
        <ListGroup.Item>Definitions – Countries List – List of Countries including number of students active / completed assigned to each</ListGroup.Item>
        <ListGroup.Item>Definitions – Course Minimums List – List of Course Minimums including number of current training Courses assigned to each</ListGroup.Item>
        <ListGroup.Item>Definitions – Course Types List – List of Course Types including number of Current/Closed Courses assigned to each</ListGroup.Item>
        <ListGroup.Item>Definitions – Crews List – List of Crews including number of Students assigned to each</ListGroup.Item>
        <ListGroup.Item>Definitions – Currencies List – List of Currencies with number of Students (current/expired) & IPs (current/expired) assigned to each</ListGroup.Item>
        <ListGroup.Item>Definitions – Currencies To Personnel XRef – List of Currencies (not obsolete) with the names of active personnel assigned to each</ListGroup.Item>
        <ListGroup.Item>Definitions – Currencies To Units XRef – List of Currencies with assigned Units within Current Training Courses</ListGroup.Item>
        <ListGroup.Item>Definitions – Current Groundings – List of Groundings with the names of personnel currently grounded</ListGroup.Item>
        <ListGroup.Item>Definitions – Definition Reports List – List of Reports with number of active users that have access to each</ListGroup.Item>
        <ListGroup.Item>Definitions – Destinations List – List of Destinations with number of completed activities over last X days</ListGroup.Item>
        <ListGroup.Item>Definitions – FIF List – List of FIFs with number of active students and instructors that have not confirmed the FIF</ListGroup.Item>
        <ListGroup.Item>Definitions – FIFs – Unconfirmed Personnel XRef – List of active FIFs with names of personnel with an open status</ListGroup.Item>
        <ListGroup.Item>Definitions – Flight Block To Person XRef – List of Flight Blocks (not obsolete) with names of Assigned Active Personnel (students and instructors)</ListGroup.Item>
        <ListGroup.Item>Definitions – Flight Blocks List – List of Flight Blocks with number of active students / instructors assigned to each</ListGroup.Item>
        <ListGroup.Item>Definitions – Groundings Access XRef – List of Groundings with names of personnel assigned to each</ListGroup.Item>
        <ListGroup.Item>Definitions – Groundings List – List of Groundings with number personnel currently grounded</ListGroup.Item>
        <ListGroup.Item>Definitions – Line Item Groups List – List of Line Item Groups with number of Line Items assigned to each</ListGroup.Item>
        <ListGroup.Item>Definitions – Line Item Groups To Line Item XRef – List of Line Item Groups (not obsolete) with assigned Line Items</ListGroup.Item>
        <ListGroup.Item>Definitions – Line Items – Not Used – List of Line Items (not obsolete) that are not currently assigned to Units in Current Training Courses</ListGroup.Item>
        <ListGroup.Item>Definitions – Locations List – List of Locations with number of active person types at each</ListGroup.Item>
        <ListGroup.Item>Definitions – Merchandise Inventory – List of merchandise items with current number in inventory</ListGroup.Item>
        <ListGroup.Item>Definitions – Merchandise Inventory Audit – List of inventory changes per merchandise item over a date range</ListGroup.Item>
        <ListGroup.Item>Definitions – Misc Activities List – List of Misc Activities with number scheduled within last X days</ListGroup.Item>
        <ListGroup.Item>Definitions – Payment Methods List – List of Payment Methods with number of activities charged to each over last X days</ListGroup.Item>
        <ListGroup.Item>Definitions – Payroll Periods List – List of Payroll Periods with number of Payroll Adjusts, Course and Admin activities assigned to each within last X days</ListGroup.Item>
        <ListGroup.Item>Definitions – Person Roles List – List of Person Roles with number of active personnel assigned to each</ListGroup.Item>
        <ListGroup.Item>Definitions – Person Roles To Personnel XRef – List of Person Roles with the names of the active assigned personnel</ListGroup.Item>
        <ListGroup.Item>Definitions – Person Subtypes List – List of Person Subtypes with the number of personnel assigned to each</ListGroup.Item>
        <ListGroup.Item>Definitions – Qualifications List – List of Qualifications with number of active Students & active Instructors assigned to each</ListGroup.Item>
        <ListGroup.Item>Definitions – Qualifications To Personnel XRef – List of Qualifications (not obsolete) with the names of active personnel assigned to each</ListGroup.Item>
        <ListGroup.Item>Definitions – Qualifications To Units XRef – List of Qualifications with assigned Units within Current Training Courses</ListGroup.Item>
        <ListGroup.Item>Definitions – Reasons List – List of Reasons per Reason Type</ListGroup.Item>
        <ListGroup.Item>Definitions – Resource Configs List – List of Resource Configurations with number of assigned Current Training Units and assigned Resources (not obsolete) to each</ListGroup.Item>
        <ListGroup.Item>Definitions – States List – List of States including number of students active / complete assigned to each</ListGroup.Item>
        <ListGroup.Item>Definitions – Student Training Calendar List – List of Student Training Calendars including number of active students assigned to each</ListGroup.Item>
        <ListGroup.Item>Definitions – Student Training Calendars To Student XRef – List of Student Training Calendars (not obsolete) with names of Active Students assigned to each</ListGroup.Item>
        <ListGroup.Item>Definitions – Unit Notes List – List of Unit Notes</ListGroup.Item>
        <ListGroup.Item>Definitions – User Defined Reports To User Type XRef – List of Reports with the User Type assigned to each</ListGroup.Item>
        <ListGroup.Item>Definitions – User Type Display Names – List of User Type Display Names</ListGroup.Item>
        <ListGroup.Item>Management – Cancellations Analysis – List of cancelled activities grouped by cancellation reason</ListGroup.Item>
        <ListGroup.Item>Management – Cancellations Analysis Per Student – List of cancelled activities with comments per student</ListGroup.Item>
        <ListGroup.Item>Management – Cancellations Analysis With Comments – List of cancelled activities with comments grouped by cancellation reason</ListGroup.Item>
        <ListGroup.Item>Management – Check Instructor Activity – List of check activities per check instructor over a date range including pass rate per check instructor</ListGroup.Item>
        <ListGroup.Item>Management – Class Standing Average Costs – Average costs for class standing</ListGroup.Item>
        <ListGroup.Item>Management – Comp Time Requests – List of Operations Comp Time Requests by date and student</ListGroup.Item>
        <ListGroup.Item>Management – Course Completion – Student Time – List of students with totals on oral time, dual time, solo time and cost at completion; display days on hold and days on ground and days to complete</ListGroup.Item>
        <ListGroup.Item>Management – Course Completion Averages – Averages of students completing courses</ListGroup.Item>
        <ListGroup.Item>Management – Credit Details – Detailed Information Regarding Credits Entered Between a Date Range</ListGroup.Item>
        <ListGroup.Item>Management – Credits Used – Detailed Information Regarding Credits Applied to Activities</ListGroup.Item>
        <ListGroup.Item>Management – Instructor Activities Per Student – List of students assigned to each instructor with activity details over a date range</ListGroup.Item>
        <ListGroup.Item>Management – Instructor Activities Per Student Summary – Number of assigned students, number of Activities (Attempted, Passed, Repeated and Cancelled), number of hours (sim, dual and oral) per instructor over a date range</ListGroup.Item>
        <ListGroup.Item>Management – Instructor Admin Activity Analysis – List of Admin Activities per Instructor</ListGroup.Item>
        <ListGroup.Item>Management – Instructor Duty Day – List of personnel duty day hours for a particular duty day</ListGroup.Item>
        <ListGroup.Item>Management – Instructor Payroll Details – Instructor hours broken out into sim/single engine/multi engine/oral over a date range</ListGroup.Item>
        <ListGroup.Item>Management – Instructor Payroll Summary – Instructor total payroll hours charged over a date range</ListGroup.Item>
        <ListGroup.Item>Management – Instructor Revenue – Refresher and course activities with instructor charges.</ListGroup.Item>
        <ListGroup.Item>Management – Instructor Schedule Productivity – Instructor training hours per calendar date (requested hours, scheduled hours and actual hours)</ListGroup.Item>
        <ListGroup.Item>Management – Instructor Schedule Productivity Summary – Instructor training hours (requested hours, scheduled hours and actual hours)</ListGroup.Item>
        <ListGroup.Item>Management – Line Item Analysis – Returns line item proficiency, variance and standard deviation for units performed over a date range</ListGroup.Item>
        <ListGroup.Item>Management – Operations – Open Activities – List of activities from Ops Dispatcher that have not been completed, cancelled or terminated</ListGroup.Item>
        <ListGroup.Item>Management – Operations – Open Course Activity Completions – List of course activities from Ops Dispatcher that are pending activity completion</ListGroup.Item>
        <ListGroup.Item>Management – Recommending Instructor Pass Rates – List of check activities per recommending instructor over a date range including pass rate per recommending instructor</ListGroup.Item>
        <ListGroup.Item>Management – Resource Revenue – List of admin, course, refresher and rental activities per resource; with total revenue</ListGroup.Item>
        <ListGroup.Item>Management – Resource Schedule Productivity – Resource hours per calendar date (requested hours, scheduled hours, and actual hours)</ListGroup.Item>
        <ListGroup.Item>Management – Resource Schedule Productivity Summary – Summary of resource hours (requested hours, scheduled hours, and actual hours)</ListGroup.Item>
        <ListGroup.Item>Management – Schedule Posting – Schedule post time, late posting information and number of requests posted per date</ListGroup.Item>
        <ListGroup.Item>Management – Schedule Requests – Not Optioned Units – Optioned / Not Optioned schedule request statistics per team per instructor per date</ListGroup.Item>
        <ListGroup.Item>Management – Schedule Requests – Pending Check Ride Analysis – Average days check rides have been in Pend Schedule before being scheduled</ListGroup.Item>
        <ListGroup.Item>Management – Self Payment Transactions – List of self payment transactions over a date range.</ListGroup.Item>
        <ListGroup.Item>Management – Self Payment Transactions Workflow – Timestamps for each status of a self payment transaction.</ListGroup.Item>
        <ListGroup.Item>Management – Student Rate Of Completion – Provides actual versus planned rate of completion per active student, per course, per instructor, per team</ListGroup.Item>
        <ListGroup.Item>Operations – Activities To Course Durations Audit – List of Completed Activities Course Activities with comparison of Course Catalogue Unit Durations against actual recorded Hobbs time and Oral time over a Date Range per Location</ListGroup.Item>
        <ListGroup.Item>Operations – Activity Count – List of Activity counts for a date Completed / Cancelled / Overdue / Open per Activity Type for a Date over a Date Range per Location</ListGroup.Item>
        <ListGroup.Item>Operations – Activity Details – Activity Details</ListGroup.Item>
        <ListGroup.Item>Operations – Adjusted Due Back Time – List of Activities having Adjusted Due Back Times over a Date Range per Location</ListGroup.Item>
        <ListGroup.Item>Operations – Aircraft Ramp List – List of aircraft with current ramp status (Divert, Flying, RON, XC)</ListGroup.Item>
        <ListGroup.Item>Operations – Daily Activity Count – Number of completed, cancelled, scheduled and open activities per activity type and calendar date</ListGroup.Item>
        <ListGroup.Item>Operations – Daily Flight Operations Summary – Number of departures and arrivals per hour for a calendar date.</ListGroup.Item>
        <ListGroup.Item>Operations – Location Training Calendar – List of Dates within a Month indicating Training / Non Training Status per Year per Location</ListGroup.Item>
        <ListGroup.Item>Operations – Next grade sheet – NextGradeSheet</ListGroup.Item>
        <ListGroup.Item>Operations – Operations – Activities List – List of Operations Activities over a Date Range per Location</ListGroup.Item>
        <ListGroup.Item>Operations – Operations – Activity Completion Resets – List of activity completion resets from operations over a date range</ListGroup.Item>
        <ListGroup.Item>Operations – Operations – Ramp In Times – Displays the times entered on the Ramp In page including: Actual Start/Stop; Maintenance Start/Stop; Activity Hobbs and Activity Tach</ListGroup.Item>
        <ListGroup.Item>Operations – Operations Ramp Out / Ramp In Analysis – List of Aircraft or Simulator activities with actual ramp out / ramp in times compared to the scheduled start / stop times.</ListGroup.Item>
        </ListGroup>
        </div>

        <div className="col-md-6 mb-3">
        <ListGroup>
        <ListGroup.Item>Operations – Overdue Activities List – List of Overdue Activities over a Date Range per Location</ListGroup.Item>
        <ListGroup.Item>Personnel – Active Personnel – List of active personnel per location</ListGroup.Item>
        <ListGroup.Item>Personnel – Class List – List of students per class.</ListGroup.Item>
        <ListGroup.Item>Personnel – Course Completions – List of students which have completed a course within a date range</ListGroup.Item>
        <ListGroup.Item>Personnel – Debit Account Balance – Debit account balances per person type</ListGroup.Item>
        <ListGroup.Item>Personnel – Debit Account Deposits – List of debit account deposits made over a date range.</ListGroup.Item>
        <ListGroup.Item>Personnel – Debit Account Transactions – List of debit account transactions</ListGroup.Item>
        <ListGroup.Item>Personnel – Debit Account Transactions Per Student – List of debit account transactions per student</ListGroup.Item>
        <ListGroup.Item>Personnel – Debit Summary – Debit account beginning balance, total deposits, total debits, net activity, and ending balance per person over a date range</ListGroup.Item>
        <ListGroup.Item>Personnel – Enrollment Certificate – Enrollment Certificate</ListGroup.Item>
        <ListGroup.Item>Personnel – Graduation Certificate – Graduation Certificate</ListGroup.Item>
        <ListGroup.Item>Personnel – Graduation Certificate FAA – Graduation Certificate FAA</ListGroup.Item>
        <ListGroup.Item>Personnel – Instructor Completions And Cancellations – List of scheduled activities per instructor, with totals on admin time, dual time, sim time and oral time</ListGroup.Item>
        <ListGroup.Item>Personnel – Instructor List – List of instructors filtered by Site, Team, Status and Person Subtype</ListGroup.Item>
        <ListGroup.Item>Personnel – Instructor List per Team – List of Instructors per Team per Location</ListGroup.Item>
        <ListGroup.Item>Personnel – Other List – List of Other Personnel per Location</ListGroup.Item>
        <ListGroup.Item>Personnel – Others List – No Groupings – List of other personnel filtered by Site, Status and Person Subtype</ListGroup.Item>
        <ListGroup.Item>Personnel – Person Currencies and Qualifications – List of personnel with awarded currencies and qualifications</ListGroup.Item>
        <ListGroup.Item>Personnel – Person Grounds – List of Personnel Grounds per Location over a date range</ListGroup.Item>
        <ListGroup.Item>Personnel – Person Pilot Log – List of a persons pilot log activities over a date range</ListGroup.Item>
        <ListGroup.Item>Personnel – Person Pilot Log Summary – Pilot log totals over a date range per person</ListGroup.Item>
        <ListGroup.Item>Personnel – Person Pilot Log With Comments – List of a persons pilot log activities over a date range with comments</ListGroup.Item>
        <ListGroup.Item>Personnel – Person Time Per Aircraft Type – Personnel total time in each aircraft type of a date range.</ListGroup.Item>
        <ListGroup.Item>Personnel – Personnel – Address Information – List of instructors, others, or students with address, phone number and email.</ListGroup.Item>
        <ListGroup.Item>Personnel – Personnel – Documents List per Person – List of uploaded documents to a person</ListGroup.Item>
        <ListGroup.Item>Personnel – Personnel – Expired Currencies – List of Currencies (non obsolete) with names of personnel that are within X days of expiration</ListGroup.Item>
        <ListGroup.Item>Personnel – Personnel – Open FIFs – Number of unconfirmed FIFs per person</ListGroup.Item>
        <ListGroup.Item>Personnel – Personnel Documents – List of each personnel that contain a document type.</ListGroup.Item>
        <ListGroup.Item>Personnel – Personnel Emergency Contact Info – Displays emergency contact information for individuals</ListGroup.Item>
        <ListGroup.Item>Personnel – Student Count – Returns a count of students based on the filter settings with the option to list the students by name</ListGroup.Item>
        <ListGroup.Item>Personnel – Student Course Comments – List of comments associated with a student</ListGroup.Item>
        <ListGroup.Item>Personnel – Student Course Dates – Student course dates including enrollment date termination date and completion date.</ListGroup.Item>
        <ListGroup.Item>Personnel – Student Debit Billing Details – List of student debit account transactions including deposits and payments over a date range per course.</ListGroup.Item>
        <ListGroup.Item>Personnel – Student Debit Billing Summary – List of student debit balances on an effective date per course.</ListGroup.Item>
        <ListGroup.Item>Personnel – Student Debit Summary – Debit account beginning balance, total deposits, total debits, net activity, and ending balance per student over a date range</ListGroup.Item>
        <ListGroup.Item>Personnel – Student Enrollment – List of students enrollments within a date range or registration term</ListGroup.Item>
        <ListGroup.Item>Personnel – Student Holds List – List of Student Holds within a date range per Location</ListGroup.Item>
        <ListGroup.Item>Personnel – Student Information Validation – List of students missing information required by ETA system</ListGroup.Item>
        <ListGroup.Item>Personnel – Student Last Unit Attempted – List of the students and the last unit attempted</ListGroup.Item>
        <ListGroup.Item>Personnel – Student List – List of Students per Instructor per Team per Location</ListGroup.Item>
        <ListGroup.Item>Personnel – Student List – No Groupings – List of students filtered by Site, Team, Status, Person Subtype, Course and Class</ListGroup.Item>
        <ListGroup.Item>Personnel – Student No Shows – List of Student No Shows over a Date Range per Location</ListGroup.Item>
        <ListGroup.Item>Personnel – Student Non Starts – List of Student Non Starts per Location over a date range</ListGroup.Item>
        <ListGroup.Item>Personnel – Student Registration Statistics – List of Courses with number of active, completed, non-start and withdrawn per Location</ListGroup.Item>
        <ListGroup.Item>Personnel – Student Registrations – List of student registrations over a date range</ListGroup.Item>
        <ListGroup.Item>Personnel – Student Withdraws – List of Student Withdraws per Location over a date range</ListGroup.Item>
        <ListGroup.Item>Personnel – Team List – List of Teams per Location</ListGroup.Item>
        <ListGroup.Item>Personnel – Users List – Displays the name, eta id, user name, account status and person type for users entered into ETA</ListGroup.Item>
        <ListGroup.Item>Personnel – Xo Sheet – Student completion of units matrix</ListGroup.Item>
        <ListGroup.Item>Planning – Planner – Reports – Resource, revenues, costs, fuel usage, fuel costs, required IPs forecasts over time</ListGroup.Item>
        <ListGroup.Item>Planning – Planner Forecasts – Resource, revenues, costs, fuel usage, fuel costs, required IPs forecasts over time</ListGroup.Item>
        <ListGroup.Item>Scheduling – Academic Schedule – Displays a list of scheduled academic activities over a date range</ListGroup.Item>
        <ListGroup.Item>Scheduling – Block Schedule Audit – List of block schedule activities over a date range including resources, class and submit/authorize/schedule personnel</ListGroup.Item>
        <ListGroup.Item>Scheduling – Location Block Schedule – List of block scheduled activities over a given date range</ListGroup.Item>
        <ListGroup.Item>Scheduling – Location Schedule – List of Scheduled activities within a Date Range per Location</ListGroup.Item>
        <ListGroup.Item>Scheduling – Person Schedule – List of Scheduled Activities within a Date Range per Person Per Location</ListGroup.Item>
        <ListGroup.Item>Scheduling – Resource Priority Flow – List of resources ordered by priority.</ListGroup.Item>
        <ListGroup.Item>Scheduling – Resource Type Allocation – List of Resource Type Allocations per Day per Location over a Date Range</ListGroup.Item>
        <ListGroup.Item>Scheduling – Resource Type Schedule – List of Scheduled Activities within a Date Range per Resource Type Per Location</ListGroup.Item>
        <ListGroup.Item>Scheduling – Schedule Posting Summary – Provides schedule posting totals and percentages related to posted requests and ops Requests for dates within a specified date range</ListGroup.Item>
        <ListGroup.Item>Scheduling – Schedule Requests – List of Schedule Requests over a Date Range per Location</ListGroup.Item>
        <ListGroup.Item>Scheduling – Schedule Requests – Not Scheduled – List of Schedule Requests Not Schedule over a Date Range per Location</ListGroup.Item>
        <ListGroup.Item>Scheduling – Schedule Requests – Ops Requests – List of Ops Requests over a Date Range per Location</ListGroup.Item>
        <ListGroup.Item>Scheduling – Schedule Requests – Statistics – For Each Date the # of Schedule Requests (scheduled / not scheduled / ops request) over a Date Range per Location</ListGroup.Item>
        <ListGroup.Item>Scheduling – Schedule Requests – Submit Info – List of schedule requests with who submitted it and when it was submitted.</ListGroup.Item>
        <ListGroup.Item>Scheduling – Scheduler Start Times Per Instructor – List of instructors and their start times that are entered into the Scheduler.</ListGroup.Item>
        <ListGroup.Item>Scheduling – Student Days Since Last Attempt – Student information related to days without completing activities</ListGroup.Item>
        <ListGroup.Item>Scheduling – Student Days Since Last Attempt Per Course – Student information related to days without completing activities</ListGroup.Item>
        <ListGroup.Item>Scheduling – Student Schedule Status – Student information related to determining schedule priority</ListGroup.Item>
        <ListGroup.Item>Scheduling – Team Schedule – List of Scheduled Activities within a Date Range per Team Per Location</ListGroup.Item>
        <ListGroup.Item>Training Records – Course Completion – Student Unit Attempts – List of students with number of unit attempts, passed, failed, incomplete and repeats; display days on hold and ground; list number of cancels and no-shows</ListGroup.Item>
        <ListGroup.Item>Training Records – Course Unit Performance Details – Result of each attempt of a unit over a date range including date, grade, instructor, Hobbs time and oral time</ListGroup.Item>
        <ListGroup.Item>Training Records – Failed Check Rides – List of failed check ride attempts with associated failed line items for each</ListGroup.Item>
        <ListGroup.Item>Training Records – Student Academic Units – Student academic unit results including line items.</ListGroup.Item>
        <ListGroup.Item>Training Records – Student Cash Flow – List of Active Students with Planned Cost, Actual Cost, Remaining Cost and Total Cost within a Course per Location</ListGroup.Item>
        <ListGroup.Item>Training Records – Student Check Rides – List of oral checks and check rides attempted over a date range</ListGroup.Item>
        <ListGroup.Item>Training Records – Student Check Rides Projection – List of Active Students with number of units to next planned Check Ride per Location</ListGroup.Item>
        <ListGroup.Item>Training Records – Student Course Minimums – List Active Students with cumulative Course Minimum times per Location</ListGroup.Item>
        <ListGroup.Item>Training Records – Student Course Performance – Lists all students in a course with repeat counts on units. Exports to Excel format</ListGroup.Item>
        <ListGroup.Item>Training Records – Student Grade sheets – Print up to 50 student grade sheets recorded within a date range</ListGroup.Item>
        <ListGroup.Item>Training Records – Student Incomplete, Repeat and Failure Rates – Student incomplete, repeat and failure rates over a date range</ListGroup.Item>
        <ListGroup.Item>Training Records – Student Observer Time – Number of observer activities and total observer time for a student over a date range</ListGroup.Item>
        <ListGroup.Item>Training Records – Student Open Academics – List of academics units per student that have not been completed</ListGroup.Item>
        <ListGroup.Item>Training Records – Student Position In Training – List of Active Students and their relative position in training (+/-) as compared to their TFS per Location</ListGroup.Item>
        <ListGroup.Item>Training Records – Student Progress – Displays student unit progression including log book hours</ListGroup.Item>
        <ListGroup.Item>Training Records – Student Progress With Payments – Displays student unit progression including log book hours and payments made</ListGroup.Item>
        <ListGroup.Item>Training Records – Student Projected Completion – List of Students with Projected Completion Dates</ListGroup.Item>
        <ListGroup.Item>Training Records – Student Tests – List of the tests recorded in training records for a student</ListGroup.Item>
        <ListGroup.Item>Training Records – Student Training Folder / Grade sheet – Grade Sheet including Pilot Log Times, TCO Minimums, Line Item Grades, Unit Grade, Student Hobbs and Student Oral Time per Unit per Course per Student per Location</ListGroup.Item>
        <ListGroup.Item>Training Records – Student Training Plan – Units with planned completion date and actual completion date from students Training Plan in Training Records</ListGroup.Item>
        <ListGroup.Item>Training Records – Student Training Statistics – Summary of the number of Units (Omitted, Completed, Remaining, Performed, Passed, Failed, Repeated) per Active Student per Location</ListGroup.Item>
        <ListGroup.Item>Training Records – Student Training Time Details – Student training activity details including flight hours, sim hours, oral hours, academic hours and cost per completed unit</ListGroup.Item>
        <ListGroup.Item>Training Records – Student Training Time Summary – List of students with total flight and oral times over a date range</ListGroup.Item>
        <ListGroup.Item>Training Records – Students Below Course Minimums – List of students that are currently short on course minimum time</ListGroup.Item>
        <ListGroup.Item>Training Records – Task Summary – Training task results based on a students performed line items</ListGroup.Item>
        <ListGroup.Item>Training Records – Unit Repeats Previous Grade Analysis – List of unit repeats over a time period grouped by previous attempt that was passed, failed or incomplete</ListGroup.Item>
        <ListGroup.Item>Training Resources – Aircraft Fuel Usage – Aircraft fuel used per activity over a date range</ListGroup.Item>
        <ListGroup.Item>Training Resources – Aircraft Fuel Usage with Cost – Aircraft fuel used and cost per activity over a date range</ListGroup.Item>
        <ListGroup.Item>Training Resources – Aircraft Hobbs Discrepancies – Aircraft hobbs discrepancies per activity</ListGroup.Item>
        <ListGroup.Item>Training Resources – Aircraft Hours – List of aircraft hours for a date range</ListGroup.Item>
        <ListGroup.Item>Training Resources – Aircraft Hours Activity Stop – List of aircraft hours based on due back time within a date range</ListGroup.Item>
        <ListGroup.Item>Training Resources – Aircraft Hours Per Aircraft Type – Total aircraft hours per aircraft type for each date over a date range.</ListGroup.Item>
        <ListGroup.Item>Training Resources – Aircraft Hours Summary – Summary of aircraft hours for a date range</ListGroup.Item>
        <ListGroup.Item>Training Resources – Aircraft Hours Summary Activity Stop – Summary of aircraft hours based on due back time for a date range</ListGroup.Item>
        <ListGroup.Item>Training Resources – Aircraft Landings – List of Aircraft with Total Number of Landings Over a Date Range</ListGroup.Item>
        <ListGroup.Item>Training Resources – Aircraft List – List of Aircraft Resources per Location</ListGroup.Item>
        <ListGroup.Item>Training Resources – Aircraft Maintenance – List of Aircraft Resources with associated maintenance times (hobbs and tach,) per Location</ListGroup.Item>
        <ListGroup.Item>Training Resources – Aircraft Status – List of Aircraft with hobbs and tach details</ListGroup.Item>
        <ListGroup.Item>Training Resources – Aircraft Status – Tach Events – List of Aircraft with hobbs and tach details</ListGroup.Item>
        <ListGroup.Item>Training Resources – Aircraft Tach Discrepancies – Aircraft tach discrepancies per activity</ListGroup.Item>
        <ListGroup.Item>Training Resources – Buildings List – List of Building Resources per Location</ListGroup.Item>
        <ListGroup.Item>Training Resources – Computers List – List of Computer Resources per Location</ListGroup.Item>
        <ListGroup.Item>Training Resources – Down Resources – List of resources that are currently down</ListGroup.Item>
        <ListGroup.Item>Training Resources – Miscellaneous Resources List – List of Miscellaneous Resources per Location</ListGroup.Item>
        <ListGroup.Item>Training Resources – Office Equipment List – List of Office Equipment per Location</ListGroup.Item>
        <ListGroup.Item>Training Resources – Resource Activities – Operations Ramp Out/In Times – Lists ramp out time, ramp in time, hobbs time and tach time per aircraft and/or simulator activity</ListGroup.Item>
        <ListGroup.Item>Training Resources – Resource Configs To Resource XRef – List of Resource Configs (not obsolete) with assigned Resources (not obsolete)</ListGroup.Item>
        <ListGroup.Item>Training Resources – Resource Configs To Units XRef – List of Resource Configs (not obsolete) with assigned Units (current training)</ListGroup.Item>
        <ListGroup.Item>Training Resources – Resource Down History – List of downs for a resource over a time period</ListGroup.Item>
        <ListGroup.Item>Training Resources – Resource Hours Per Activity Type – List of admin, course, refresher and rental activities for each resource, total hours and average hours per resource and resource type</ListGroup.Item>
        <ListGroup.Item>Training Resources – Resource List – List of Resources per Location per Category</ListGroup.Item>
        <ListGroup.Item>Training Resources – Resource Monthly Usage – List of aircraft and/or simulators with the number of activities for each day of a month</ListGroup.Item>
        <ListGroup.Item>Training Resources – Resource PMC Times – List of PMC Time for a resource over a date range.</ListGroup.Item>
        <ListGroup.Item>Training Resources – Resource Revenue Per Activity Type – List of admin, course, refresher and rental activities per resource; with total revenue and average for revenue per resource and resource type</ListGroup.Item>
        <ListGroup.Item>Training Resources – Resource Times Audit – Resource hobbs and tach details per activity</ListGroup.Item>
        <ListGroup.Item>Training Resources – Resource Times Discrepancy – Resource hobbs and tach discrepancies per activity</ListGroup.Item>
        <ListGroup.Item>Training Resources – Resource Type Usage per Course – Number of hours performed in each resource type per course over a date range</ListGroup.Item>
        <ListGroup.Item>Training Resources – Resource Types List – List of Resources Types per Location with number of Resources Total and number of Resources Downed</ListGroup.Item>
        <ListGroup.Item>Training Resources – Resource Types To Units XRef – List of Resource Types with assigned Units within Current Training Courses</ListGroup.Item>
        <ListGroup.Item>Training Resources – Resource Utilization Detail – List of Resources with utilization rates per location over a date range.</ListGroup.Item>
        <ListGroup.Item>Training Resources – Resource Utilization Summary – Summary list of Resources with utilization rates per location over a date range.</ListGroup.Item>
        <ListGroup.Item>Training Resources – Rooms List – List of Rooms per Location</ListGroup.Item>
        <ListGroup.Item>Training Resources – Simulator Hobbs Discrepancies – Simulator hobbs discrepancies per activity</ListGroup.Item>
        <ListGroup.Item>Training Resources – Simulator Hours – List of simulator hours for a date range</ListGroup.Item>
        <ListGroup.Item>Training Resources – Simulator Hours Activity Stop – List of simulator hours based on due back time within a date range</ListGroup.Item>
        <ListGroup.Item>Training Resources – Simulator Hours Summary Activity Stop – Summary of simulator hours based on due back time for a date range</ListGroup.Item>
        <ListGroup.Item>Training Resources – Simulators List – List of Simulators per Location</ListGroup.Item>
        <ListGroup.Item>Training Resources – Vehicles List – List of Vehicles per Location</ListGroup.Item>
        </ListGroup>
        </div>
        </div>

        </Container>
        <Footer />
        </div>

    )
  }
  
  export default TalonETAReports;

