import React, { useEffect } from 'react';
import "../css/Home.css"
import "../css/Video.css";
//import "../css/slider.css"
import "bootstrap/dist/css/bootstrap.css"; 
import { Row, Container} from "react-bootstrap"
import Image from 'react-bootstrap/Image';
import Navigation from "./navigation";
import Infobar from '../infobar';
import Footer from "../footer";
import Video from '../Video';
import AOS from 'aos';
import "aos/dist/aos.css";


//import Slider from "./slider.css";

function HomePage() {

    useEffect(() => {
        AOS.init ({ duration: 1000 });
        document.title = 'Home - Talon Systems';
    }, []);

    return (
        <div>
        <Infobar />
        <Navigation />


        <Container>
        <Row className="mb-3"></Row>

        <Video />

        <div className="row boxes">
        <div className="col-md-4 mb-3 mt-3 d-none d-lg-block"><hr /></div>
        <div className="col-md-4 mb-3 mt-3 d-none d-lg-block"><h1>Our Products</h1></div>
        <div className="col-md-4 mb-3 mt-3 d-none d-lg-block"><hr /></div>
        <div className="col-md-12 mb-3 mt-3 d-lg-none"><h1>Our Products</h1></div> 
        </div>
  
        <div data-aos-delay="500" data-aos="fade-right" className="row align-middle mt-3 d-flex align-items-center boxes">
        <div className="col-md-6"> <Image src="talonsystemsimages/homeetaimg.webp" rounded /> </div>
        <div className="col-md-6 mt-3">
            <h2>TalonETA</h2>
            <h2><b>E</b>ducation & <b>T</b>raining <b>A</b>dministration</h2>
            <p className="text-start">Our Flight Training Management software, supports many thousands of users and thousands of flight training activities per day, all around the world. ETA system functions and features include Curriculum Management, Personnel Management, Training Resources Management, Resource Planning, Scheduling, Student Flight Training Management, Operations Flight Dispatch, Flight Following, F.R.A.T. (Flight Risk Assessment Tool), Training Records, Cashier, Instructor Payroll and more!
            <br />
            </p>
            
            <p className="text-center"><a href="/taloneta" className="btn btn-primary btn-lg btn-block" role="button" aria-pressed="true">Learn more about TalonETA!</a></p>
        </div>
        </div>
        
        <div data-aos-delay="500" data-aos="fade-left" className="row align-middle d-flex align-items-center boxes">
        <div className="col-md-6 mt-3 d-none d-md-block">
            <h2>TalonRMS</h2>
            <h2><b>R</b>esource <b>M</b>aintenance <b>S</b>ystem</h2>
            <p className="text-start">RMS, powered by Talon Systems, enhances flight school management by offering complete control over fleet, inventory, and maintenance. Seamlessly integrated with ETA’s Dispatch module, it provides detailed records and real-time access to maintenance data, including airworthiness directives and service bulletins.
            Technicians can effortlessly manage inspections, parts, and repairs, with features for uploading and linking documents to records. RMS intelligently prioritizes aircraft for dispatch based on maintenance needs, ensuring efficient fleet utilization And so much more!
            <br />
            </p>

            <p className="text-center"><a href="/talonrms" className="btn btn-primary btn-lg btn-block" role="button" aria-pressed="true">Learn more about TalonRMS!</a></p>
            </div>
            <div className="col-md-6 d-none d-md-block"><Image src="talonsystemsimages/homermsimg.webp" rounded /></div>


            <div className="col-md-6 d-md-none mt-3">

            <div className="col-md-6 d-md-none"><Image src="talonsystemsimages/homermsimg.webp" rounded /></div>


            <h2>TalonRMS</h2>
            <h2><b>R</b>esource <b>M</b>aintenance <b>S</b>ystem</h2>
            <p className="text-start">RMS, powered by Talon Systems, enhances flight school management by offering complete control over fleet, inventory, and maintenance. Seamlessly integrated with ETA’s Dispatch module, it provides detailed records and real-time access to maintenance data, including airworthiness directives and service bulletins.
            Technicians can effortlessly manage inspections, parts, and repairs, with features for uploading and linking documents to records. RMS intelligently prioritizes aircraft for dispatch based on maintenance needs, ensuring efficient fleet utilization And so much more!
            <br />
            </p>

            <p className="text-center"><a href="/talonrms" className="btn btn-primary btn-lg btn-block" role="button" aria-pressed="true">Learn more about TalonRMS!</a></p>
            </div>



        </div>


        <div data-aos-delay="500" data-aos="fade-right" className="row align-middle d-flex align-items-center mt-3 boxes">
        <div className="col-md-6 mb-3"><Image src="talonsystemsimages/homesmartimg.webp" rounded /></div>
        <div className="col-md-6 mb-3">
            <h2>TalonSMART</h2>
            <h2><b>S</b>afety <b>M</b>anagement <b>A</b>nd <b>R</b>eporting <b>T</b>racker</h2>
            <p className="text-start">TalonSMART allows users to report incidents and receive email updates regarding the status. Users can choose to be anonymous. Managers can create and manage investigations of incident reports. Identify hazards, Assess the Risk, Build corrective actions, Assign responsibilities, implement, and audit effectiveness. TalonSMART will arm your organization with a systematic approach to achieving acceptable levels of safety risk.
            <br />
            </p>
            <p className="text-center"><a href="/talonsmart" className="btn btn-primary btn-lg btn-block" role="button" aria-pressed="true">Learn more about TalonSMART!</a></p>
            </div>
        </div>

        </Container>
        <Footer />
        </div>
    );
   
  }

export default HomePage;