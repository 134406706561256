import React, { useEffect } from 'react';
import HomeVideo from "./assets/planevid.mp4"
import "bootstrap/dist/css/bootstrap.css"; 
import "./css/Video.css";
import AOS from 'aos';
import "aos/dist/aos.css";
// import Image from 'react-bootstrap/Image';

const Video = () => {

  useEffect(() => {
    AOS.init ({ duration: 1000 });
  }, []);

  return (
<div>

  <div className="row">
  {/* <h3 className="d-md-none"><b>Flight Training, Maintenance,  Safety</b></h3> */}
  <div className="col-10 d-md-none boxes">
  <h3 data-aos-easing="ease-in-sine" data-aos="zoom-out" data-aos-delay="200"  className="d-md-none"><b>The Aviation Management Solution:</b></h3>
  <h3 data-aos-easing="ease-in-sine" data-aos="zoom-in-right" data-aos-delay="1000" className="d-md-none"><b>Flight Training,</b></h3>
  <h3 data-aos-easing="ease-in-sine" data-aos="zoom-in" data-aos-delay="1000" className="d-md-none"><b>Maintenance,</b></h3>
  <h3 data-aos-easing="ease-in-sine" data-aos="zoom-in-left" data-aos-delay="1000" className="d-md-none">and <b>Safety</b></h3>
    </div>

    <div className="col-1 mb-3"></div>
      <div className="col-10 mb-3 boxes">        
          <div className="VideoBG">
            <video src={HomeVideo} autoPlay loop muted playsInline controls={false} webkit-playsinline="true" preload="auto" poster="images/staticplanevid.png" />  
            {/* <Image src="talonsystemsimages/staticplanevid.png" /> */}

            <div data-aos="fade" data-aos-delay="2000" data-aos-duration="3000" className="VideoText w-100 position-absolute top-0 start-50 translate-middle-x">
              <h1 className="d-none d-md-block mt-3">Real-Time Peace of Mind, Anywhere, Anytime!</h1>
              <h3 className="d-none d-md-block">Management Solution for Flight Training, Maintenance, and Safety</h3>
              
              <h3 className="d-md-none">"Real-Time Peace of Mind, Anywhere, Anytime!"</h3>
          </div>
      </div>
    </div>

    <div className="col-1 mb-3"></div>
  </div>
  
</div>

  );
};

export default Video;