import React from "react";
import Navigation from "./navigation";
import { Row, Container} from "react-bootstrap"
import "../css/ThankYou.css"
import Footer from '../footer';
// import Button from 'react-bootstrap/Button';
// import Form from 'react-bootstrap/Form';
// import InputGroup from 'react-bootstrap/InputGroup';
// import Infobar from '../infobar';

function ThankYouPage() {

  return (
    <div>
    <Navigation />
    <Container>

    <Row className="mb-5 mt-3"></Row>

        <div className="col-md-12 mb-3 mx-auto"><div>
      <div>
        <div className="trans">
           <h1 className="thankyouheader">Thank you!</h1>

          <p>For giving us the opportunity to serve the Flight Education Community!</p>
          <p>Expect an expedient reply!</p>
        </div>
        <div>
          <p></p>
        </div>
      </div>
    </div> </div>
        

    </Container>
    <Footer />
    </div>
  );
}

export default ThankYouPage;