import Carousel from 'react-bootstrap/Carousel';
// import ExampleCarouselImage from 'components/ExampleCarouselImage';
import { Row, Container, Col} from "react-bootstrap"
import "../css/TalonSMART.css"

function ScreenshotCarousel() {
  return (
    <Container>

<Row>
    <Col>
    <Carousel data-bs-theme="dark"  fade>
      <Carousel.Item interval={5000}>

        <img className="d-block w-100" src='talonsystemsimages/SMART/ss1.png' alt="Risk Matrix" text="First slide" />
      </Carousel.Item>

      <Carousel.Item interval={5000}>
        <img className="d-block w-100" src='talonsystemsimages/SMART/ss2.png' alt="Hazard to Risk Association" text="Second slide" />
      </Carousel.Item>

      <Carousel.Item interval={5000}>
        <img className="d-block w-100" src='talonsystemsimages/SMART/ss3.png' alt="Current Investigations" text="Third slide" />
      </Carousel.Item>

      <Carousel.Item interval={5000}>
        <img className="d-block w-100" src='talonsystemsimages/SMART/ss4.png' alt="Investigation Findings" text="Fourth slide" />
      </Carousel.Item>

      <Carousel.Item interval={5000}>
        <img className="d-block w-100" src='talonsystemsimages/SMART/ss5.png' alt="Incident Details" text="Fifth slide" />
      </Carousel.Item>

      <Carousel.Item interval={5000}>
        <img className="d-block w-100" src='talonsystemsimages/SMART/ss6.png' alt="HFACS Codes" text="sixth slide" />
      </Carousel.Item>

      <Carousel.Item interval={5000}>
        <img className="d-block w-100" src='talonsystemsimages/SMART/ss7.png' alt="Investigation Details" text="Seventh slide" />
      </Carousel.Item>

      <Carousel.Item interval={5000}>
        <img className="d-block w-100" src='talonsystemsimages/SMART/ss8.png' alt="Form Management" text="Eight slide" />
      </Carousel.Item>

      <Carousel.Item interval={5000}>
        <img className="d-block w-100" src='talonsystemsimages/SMART/ss9.png' alt="Flight Information" text="Nineth slide" />
      </Carousel.Item>

    </Carousel>
    </Col>
</Row>

    </Container>
  );
}

export default ScreenshotCarousel;