import Carousel from 'react-bootstrap/Carousel';
// import ExampleCarouselImage from 'components/ExampleCarouselImage';
import { Row, Container, Col} from "react-bootstrap"
import "../css/TalonSMART.css"

function SmartCarousel() {
  return (
    <Container>

<Row className="zindexing">
    <Col>
    <Carousel fade>

      <Carousel.Item interval={5000}>
      
      <h3 className="smartbackground h3color my-3">Do you have strategies in place for mitigating risk? <br />Are they effective?</h3>
          <p className="smartbackground h1color">TalonSMART will arm your organization with a systematic approach to achieving acceptable levels of safety risk</p>
        <img className="d-block w-100" src='talonsystemsimages/SMART/smart_slider_2_image_1.png' alt="Safety Manager Looking at Big Engine" text="First slide" />

      </Carousel.Item>

      <Carousel.Item interval={5000}>
      <h3 className="smartbackground h3color my-3">A comprehensive web-based incident reporting solution</h3>
      <p className="smartbackground h1color">TalonSMART allows users to report incidents and receive email updates regarding the status. Users can choose to be anonymous. Managers can create and manage investigations of incident reports.</p>
        <img className="d-block w-100" src='talonsystemsimages/SMART/smart_slider_2_image_2.png'  alt="Bent Propeller" text="Second slide" />
      </Carousel.Item>

      <Carousel.Item interval={5000}>
      <h3 className="smartbackground h3color my-3">TalonSMART helps you identify hazards and risks</h3>
      <p className="smartbackground h1color">Identify hazards, Assess the Risk, Build corrective actions, Assign responsibilities, implement, and audit effectiveness</p>
        <img className="d-block w-100" src='talonsystemsimages/SMART/smart_slider_2_image_3.png' alt="Danger Symbols" text="Third slide" />
      </Carousel.Item>

      <Carousel.Item interval={5000}>
      <h3 className="smartbackground h3color my-3">Completely user-defined forms</h3>
      <p className="smartbackground h1color">You define all drop down lists, labels, whether a field is required or hidden, and how/if the data collected will eventually be deidentified (destroyed)</p>
        <img className="d-block w-100" src='talonsystemsimages/SMART/smart_slider_2_image_4.png' alt="Form Management Preview" text="Fourth slide" />
      </Carousel.Item>

      <Carousel.Item interval={5000}>
      <h3 className="smartbackground h3color my-3">Safety Management System (SMS)</h3>
      <p className="smartbackground h1color">'A SMS provides a systematic way to identify hazards and control risks while maintaining assurance that these risk controls are effective.' 
      <br /> FAA - SASO Safety Promotion Briefing - Manager's Toolkit</p>
        <img className="d-block w-100" src='talonsystemsimages/SMART/smart_slider_2_image_5.png' alt="Safety Quote From FAA" text="Fifth slide" />
      </Carousel.Item>
    </Carousel>
    </Col>
</Row>

    </Container>
  );
}

export default SmartCarousel;