import React, { useEffect } from 'react';
import Navigation from "./navigation";
import { Row, Container} from "react-bootstrap"
import "../css/ContactUs.css"
import Footer from '../footer';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import AOS from 'aos';
import "aos/dist/aos.css";




function ContactUsPage() {

  useEffect(() => {
    AOS.init ({ duration: 1000 });
    document.title = 'Contact Us - Talon Systems';
}, []);

  return (
    <div>
    <Navigation />
    <Container>
{/* I removed the Infobar since they are already on the contact us page */}
    <Row className="mb-3 mt-3">
         
         </Row>

        <div class="row boxes">
          <div class="col-md-12 mx-3 mt-4">
            <a class="me-3 contactuslinks" href="tel:+18172514321"><h3><FontAwesomeIcon icon={faPhone} shake size="1x"/><br /> <br /><b>Speak to a Live Representative: </b><br />  +1 (817) 251-4321</h3></a>
            <a class="me-3 contactuslinks" href="tel:+18662582566"><h3>Call TOLL Free: <br />  +1 (866) 258-2566</h3></a>
          </div>

        <div class="col-md-12 mb-3">
        <Form action="https://apps5.talonsystems.com/tseta/php/talonsystemsmail.php" method="post">

      <InputGroup className="mb-3">
        <InputGroup.Text className="d-none d-md-block"><b>Name:</b></InputGroup.Text>
        <Form.Control className="d-none d-md-block" name="name" placeholder="First and Last Name" aria-label="Name" required/>
      </InputGroup>

      <InputGroup className="mb-3">
        <InputGroup.Text className="d-none d-md-block"><b>Email:</b></InputGroup.Text>
        <Form.Control className="d-none d-md-block" type="email" placeholder="Email" name="emailaddress" aria-label="Email" required/>
      </InputGroup>

      <InputGroup className="mb-3">
        <InputGroup.Text className="d-none d-md-block"> <b>Students:</b></InputGroup.Text>
        <Form.Control className="d-none d-md-block" name="students" placeholder="Quantity" aria-label="Students" required/>
        <InputGroup.Text className="d-none d-md-block"><b>Aircraft:</b></InputGroup.Text>
        <Form.Control className="d-none d-md-block" name="aircraft" placeholder="Quantity" aria-label="Aircraft" required/>
        <InputGroup.Text className="d-none d-md-block"><b>Talon System:</b></InputGroup.Text>
        <Form.Control className="d-none d-md-block" name="system" placeholder="ETA, RMS, SMART" aria-label="System" required/>
      </InputGroup>


      <Form.Group className="d-none d-md-block mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Control name="comments" placeholder="What you are looking for us to be able to help you with?" as="textarea" rows={5} required/>
      </Form.Group>
      <Button className="d-none d-md-block mb-3" variant="primary" type="submit">
        Submit 
      </Button>
    </Form>
    </div>


{/* Mobile Version of the Contact Us Form */}

    <div class="col-md-12 mb-3">
        <Form action="https://apps5.talonsystems.com/tseta/php/talonsystemsmail.php" method="post">

      <InputGroup className="d-md-none mb-3">
        <InputGroup.Text><b>Name:</b></InputGroup.Text>
        <Form.Control name="name" placeholder="First and Last Name" aria-label="Name" required/>
      </InputGroup>

      <InputGroup className="d-md-none mb-3">
        <InputGroup.Text><b>Email:</b></InputGroup.Text>
        <Form.Control type="email" placeholder="Email" name="emailaddress" aria-label="Email" required/>
      </InputGroup>

      <InputGroup className="mb-3">
        <InputGroup.Text className="d-md-none"><b>Students:</b></InputGroup.Text>
        <Form.Control className="d-md-none" name="students" placeholder="Quantity" aria-label="Students" required/>
        </InputGroup>

        <InputGroup className="d-md-none mb-3">
        <InputGroup.Text className="d-md-none"><b>Aircraft:</b></InputGroup.Text>
        <Form.Control className="d-md-none" name="aircraft" placeholder="Quantity" aria-label="Aircraft" required/>
        </InputGroup>

        <InputGroup className="d-md-none mb-3">
        <InputGroup.Text className="d-md-none" ><b>Talon System:</b></InputGroup.Text>
        <Form.Control className="d-md-none" name="system" placeholder="ETA, RMS, SMART" aria-label="System" required/>
      </InputGroup>

      <Form.Group className="d-md-none mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Control name="comments" placeholder="What you are looking for us to be able to help you with?" as="textarea" rows={5} required/>
      </Form.Group>
      <Button className="d-md-none" variant="primary" type="submit">
        Submit 
      </Button>
    </Form>
    </div>

        </div>


        <div class="row boxes">
        <div class="col-md-12 mb-3">
        <p class="text-start text-wrap">
        <h1>Located in Grapevine, TX</h1>
        <h3>1023 South Main Street Ste. 203<br />Grapevine, Texas 76051 USA</h3></p>
        <p class="text-start text-wrap">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d995.5994620666731!2d-97.07740914771605!3d32.93030267070869!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c2b2415b552f1%3A0x9a79be589bb429cb!2s1023%20S%20Main%20St%2C%20Grapevine%2C%20TX%2076051!5e0!3m2!1sen!2sus!4v1716498039057!5m2!1sen!2sus" width="100%" height="450" allowfullscreen="true" title="Talon Systems Google Maps Address" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </p>
    </div>

        </div>

        

    </Container>
    <Footer />
    </div>
  );
}

export default ContactUsPage;