import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./css/global.css"
import ReactGA from "react-ga4";

ReactGA.initialize("G-VZJCTYW4XG");

ReactGA.send({ hitType: "pageview", page: "/my-path" });


const container = document.getElementById('root');
const root = createRoot(container); 
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();