// components/FooterStyles.js

import styled from "styled-components";

export const Box2 = styled.div`
	background: #264386;
	position: fixed;
  	top: 0;
	margin-bottom: 20px;
	padding: 10px 0px 0px 0px;
	width: 100%;
	z-index:4;
	${'' /* @media (max-width: 800px) {
		background: #000;
	position: absolute;
  	top: 0;
	width: 100%;
	z-index:1;
	} */}
`;

export const FooterContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
	flex-grow: 1;
	/* background: red; */
`;

export const Heading = styled.p`
	font-size: 1.2em;
	color: #F5F5F5;
	font-weight: bold;
	vertical-align: baseline;
	a:link {color:#F5F5F5; text-decoration: none; cursor:help}      /* unvisited link */
	a:visited {color:#F5F5F5; text-decoration: none; cursor:help}  /* visited link */
	a:hover {color:#F5F5F5; text-decoration: underline; cursor:help}
	margin-bottom: 0;
`;