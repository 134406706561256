import React, { useEffect } from 'react';
import "../css/TalonETA.css"
import Navigation from "./navigation";
import Footer from '../footer';
import { Row, Container} from "react-bootstrap"
import Image from 'react-bootstrap/Image';
import ListGroup from 'react-bootstrap/ListGroup';
import Infobar from '../infobar';
import AOS from 'aos';
import "aos/dist/aos.css";


function TalonETAPage() {
  useEffect(() => {
    AOS.init ({ duration: 1000 });
    document.title = 'ETA - Talon Systems';
}, []);
    return (
<div>
<Infobar />
<Navigation />
<Container>
        <Row className="mb-5 mt-3"></Row>
  
        <div data-aos-delay="500" data-aos="fade" className="row boxes">
        <div className="col-md-2 mb-3 mt-3 d-none d-lg-block"><hr /></div>
        <div className="col-md-8 mb-3 mt-3 d-none d-lg-block"><h1>ETA - Flight Training Management Software</h1></div>
        <div className="col-md-2 mb-3 mt-3 d-none d-lg-block"><hr /></div>
        <div className="col-md-12 mb-3 mt-3 d-lg-none"><h1>ETA - Flight Training Management Software</h1></div> 


        {/* <p className="text-center"><a href="/talonetaguidedtour" className="btn btn-primary btn-lg btn-block" role="button" aria-pressed="true">Check out a Junior Version of our Demonstration!</a></p> */}


        <div className="col-md-6"><h2>ETA - <b>E</b>ducation & <b>T</b>raining <b>A</b>dministration</h2><Image src="talonsystemsimages/etaetatitle.webp" rounded /> <p className="text-start text-wrap">Our hallmark product, ETA, is a 100% web-based training management system designed, by Talon Systems, specifically for the management of flight training; curriculum, student records, resources, flight scheduling and following/operations. The name of our training management system is ETA (Education and Training Administration) and it can be used to support all facets of your organization: Curriculum Management, Instructor Records, Student Training Records, Student Accounting, Resource Management, Resource Planning, Scheduling, Operations, Flight Following and more! 
        Those of us who have spent time involved with flight training know the daily management of a pilot training program requires constant monitoring and decision-making based on accurate and current information. It requires in depth knowledge of students, instructors, aircraft, availabilities, regulations, course syllabus and many other factors and elements comprising the flight training domain.</p></div>
        <div className="col-md-6"><h2>User Friendly</h2><Image src="talonsystemsimages/etauserfriendly.webp" rounded /><p className="text-start text-wrap">We at Talon Systems have created a tool to corral this flow of information, organize it and provide access to it in a manner that is designed to be user friendly and provide students, instructors and managers info they need WHEN they need it with a click of a mouse. When you consider all the factors that go into a single training flight and compound the lesson execution with uncontrollable and unpredictable factors such as weather, air traffic control, medical, and aircraft issues, you understand right decisions based on the right information at the right time are critical to controlling the risk and running a profitable flight training operation</p></div>
        </div>
        
        <div data-aos-delay="500" data-aos="fade-right" className="row boxes">
        <div className="col-md-6 mb-3"><h2>Meets Requirements for Electronic <br /> Record Keeping</h2><Image src="talonsystemsimages/etaelectronicrecordkeeping.webp" rounded /><p className="text-start">The keys to dealing with these issues successfully are experience and information. Whether you’re an instructor pilot, aircraft or simulator maintenance manager, director of the program or chief pilot, nothing replaces the knowledge gained through lessons learned while spending time on the job. We can’t increase the working knowledge of your staff overnight but we can provide them a tool that puts information at their finger tips. Timely and accurate Information is the key to making the right decisions quickly. The amount of data that is produced on a daily basis by a flight training operation is staggering. Yet most of it continues to be collected via paper based processes. If computer systems are being used they are most likely either home grown and costly to support, or antiquated technology and often use software that was not specifically designed for managing flight training.</p></div>
        <div className="col-md-6 mb-3"><h2>Designed by <br /> Aviators</h2><Image src="talonsystemsimages/etaaviators.webp" rounded /><p className="text-start">The primary reason ETA is the best system available is because it was designed by flight training managers, pilots and aerospace software engineers, not to mention that ETA has been an industry standard since 2001!
        Check out our web site and see why Embry-Riddle Aeronautical University, Western Michigan University, Florida Institute of Technology, Southern Illinois University and countless other universities, colleges and commercial flight schools have chosen to implement ETA at all their campuses. 
        If interested, please <a className="etalink" href="/contactus">Contact Us</a> and we can discuss your needs with regard to information management.</p></div>
        </div>
        

        <div data-aos-delay="500" data-aos="fade-down" className="row boxes">
        <div className="col-md-4 mb-3 mt-3 d-none d-lg-block"><hr /></div>
        <div className="col-md-4 mb-3 mt-3 d-none d-lg-block"><h1>Key Features</h1></div>
        <div className="col-md-4 mb-3 mt-3 d-none d-lg-block"><hr /></div>
        <div className="col-md-12 mb-3 mt-3 d-lg-none"><h1>Key Features</h1></div> 
        </div>

        <div className="row boxes">
          <div data-aos-delay="500" data-aos="fade-right" className="col-md-4"><h2>Course Management</h2><Image src="talonsystemsimages/etacoursemanagement.webp" rounded />
          <p className="text-start">  
          <ListGroup>
           <ListGroup.Item>Courses have subsets of Stages, Phases, Units, and Line Items (objectives)</ListGroup.Item>
            <ListGroup.Item>Can assign Objectives and Completion Standards to all course components</ListGroup.Item>
            <ListGroup.Item>Multiple syllabus rules can be associated with each course component</ListGroup.Item>
            <ListGroup.Item>Tracks students completion of Line Item training objectives</ListGroup.Item>
            <ListGroup.Item>Incomplete Line Items are automatically listed on next lesson grade sheet and tracked to satisfactory completion</ListGroup.Item>
            <ListGroup.Item>Prerequisite flow ensures correct training lesson sequencing</ListGroup.Item>
            <ListGroup.Item>Management authorization required to perform training activities not in accordance with user defined syllabus design</ListGroup.Item>
            <ListGroup.Item>Assign unit durations, required resources, resource configurations, and instructor currencies and qualifications</ListGroup.Item>
            <ListGroup.Item>User defined grading standards (relative or absolute grading standards)</ListGroup.Item>
            <ListGroup.Item>Set line items (training objectives) as Required, Optional or Must Pass</ListGroup.Item>
            <ListGroup.Item>Set rules for authorized repeats for complete or incomplete training activities</ListGroup.Item>
            <ListGroup.Item>Tightly integrated with scheduling functions</ListGroup.Item>
          </ListGroup>
          </p>
          </div>

          <div className="col-md-4"><h2>Student Records</h2><Image src="talonsystemsimages/etastudentrecords.webp" rounded /><p className="text-start">
          <ListGroup>
          <ListGroup.Item>Comprehensive training records of students in current and past courses</ListGroup.Item>
          <ListGroup.Item>Individual lesson grade sheets</ListGroup.Item>
          <ListGroup.Item>Record student test grades</ListGroup.Item>
          <ListGroup.Item>Track student currencies and qualifications including endorsements</ListGroup.Item>
          <ListGroup.Item>Real time student data</ListGroup.Item>
          <ListGroup.Item>Know whether a student is ahead or behind of training plan</ListGroup.Item>
          <ListGroup.Item>Project check ride dates and course completion date</ListGroup.Item>
          <ListGroup.Item>Determine up to the minute cost of completed training and projected costs through course completion</ListGroup.Item>
          <ListGroup.Item>View training information such as last activity, current activity and next activity</ListGroup.Item>
          <ListGroup.Item>Individual and customizable student training plans</ListGroup.Item>
          <ListGroup.Item>Track course minimums (dual, solo, night, cross-country) requirements in real time</ListGroup.Item>
          <ListGroup.Item>Automated audits of training records – completion of lessons, hours, training objectives</ListGroup.Item>
        </ListGroup>
        </p></div>

        <div data-aos-delay="500" data-aos="fade-left" className="col-md-4"><h2>Scheduling</h2><Image src="talonsystemsimages/etascheduling.webp" rounded /><p className="text-start">  
          <ListGroup>
            <ListGroup.Item>View your schedule through the Internet</ListGroup.Item>
            <ListGroup.Item>Schedule unavailability (vacations, dental and doctor appointments)</ListGroup.Item>
            <ListGroup.Item>Schedule miscellaneous activities (safety meetings, instructor briefings)</ListGroup.Item>
            <ListGroup.Item>Instructors can schedule activities in advance</ListGroup.Item>
            <ListGroup.Item>Based on the training course, ETA determines the next activity that should be scheduled for each student</ListGroup.Item>
            <ListGroup.Item>Verifies required instructor currencies and qualifications prior to scheduling</ListGroup.Item>
            <ListGroup.Item>Verifies required student qualifications and currencies prior to scheduling</ListGroup.Item>
            <ListGroup.Item>Schedule personnel and resources for student training and non-student training events</ListGroup.Item>
            <ListGroup.Item>Training activities scheduled in accordance with syllabus design</ListGroup.Item>
            <ListGroup.Item>Resource availability integrated with scheduling functions</ListGroup.Item>
            <ListGroup.Item>Capability to schedule flight, simulator, oral or academic training activities</ListGroup.Item>
            <ListGroup.Item>Block Scheduling – Schedule instructors, students, and resources based on pre-defined “blocks of time”</ListGroup.Item>
          </ListGroup>
          </p></div>


        </div>
        
        <div data-aos-delay="500" data-aos="fade-down" className="row boxes">

        <div data-aos-delay="500" data-aos="fade-right" className="col-md-4"><h2>Instructor Performance</h2><Image src="talonsystemsimages/etainstructorperformance.webp" rounded /><p className="text-start">
        <ListGroup>
          <ListGroup.Item>Track instructor currencies and monitor expiration dates</ListGroup.Item>
          <ListGroup.Item>Track instructor qualifications</ListGroup.Item>
          <ListGroup.Item>View instructor pass rates for student checkrides</ListGroup.Item>
          <ListGroup.Item>Warning and tracking of instructor flight hour violations</ListGroup.Item>
          <ListGroup.Item>Monitor individual instructor workload</ListGroup.Item>
          <ListGroup.Item>Automatically generates payroll entries for hourly instructors</ListGroup.Item>
          <ListGroup.Item>Management alerts for training activities that do not conform to syllabus</ListGroup.Item>
          <ListGroup.Item>Who, what and when audit trail for elements of every training activity (schedule, authorize, ramp out, ramp in and activity completion)</ListGroup.Item>
        </ListGroup>
        </p></div>

          <div className="col-md-4"><h2>Flight Tracking</h2><Image src="talonsystemsimages/etaflighttracking.webp" rounded /><p className="text-start">
        <ListGroup>
          <ListGroup.Item>Real time schedule execution and tracking</ListGroup.Item>
          <ListGroup.Item>Notification of overdue aircraft</ListGroup.Item>
          <ListGroup.Item>Electronic student check-in and instructor authorization</ListGroup.Item>
          <ListGroup.Item>Aircraft\simulator check out and check in (can be interfaced to Talon’s maintenance system RMS)</ListGroup.Item>
          <ListGroup.Item>Automated billing with electronic Hobbs and Tach time trackers</ListGroup.Item>
          <ListGroup.Item>Computerized training lesson completion (pilot log, unit grade sheet, flight time or simulator minimums, and next lesson review)</ListGroup.Item>
          <ListGroup.Item>Electronic student grade sheet incorporates syllabus restrictions, rules and grading standards</ListGroup.Item>
          <ListGroup.Item>Maintains electronic log book for instructors and students</ListGroup.Item>
          <ListGroup.Item>Update currencies and qualifications</ListGroup.Item>
          <ListGroup.Item>Electronic passenger manifests</ListGroup.Item>
          <ListGroup.Item>Cross-country flight following</ListGroup.Item>
          <ListGroup.Item>Flight Information File review and sign off</ListGroup.Item>
        </ListGroup>
        </p></div>
          <div data-aos-delay="500" data-aos="fade-left" className="col-md-4"><h2>Cashier</h2><Image src="talonsystemsimages/etacashier.webp" rounded /><p className="text-start">
        <ListGroup>
          <ListGroup.Item>Computed costs for an activity based on user-defined contract rates or on individualized student rates</ListGroup.Item>
          <ListGroup.Item>Provides total cost of each activity plus a breakout of resource cost, instructor cost and surcharge (if activated)</ListGroup.Item>
          <ListGroup.Item>Cashier check out and payment is tracked for “pay as you go” customers</ListGroup.Item>
          <ListGroup.Item>Student account types can be set to automatically debit from a debit account with warnings and/or hard stops if user defined minimum balance is not maintained.</ListGroup.Item>
          <ListGroup.Item>Provides electronic and paper invoice showing aircraft/simulator time/cost, instructor time/cost and miscellaneous charges</ListGroup.Item>
          <ListGroup.Item>Can be used for student training, administrative and aircraft rental activities as well as merchandise (user defined) sales</ListGroup.Item>
          <ListGroup.Item>Comprehensive financial reports including debit account balances/transactions and trial balance</ListGroup.Item>
        </ListGroup>
        </p></div>
        </div>

        <div data-aos-delay="500" data-aos="fade-up-right" className="row boxes">
          <div className="col-md-4"><h2>Extras</h2><Image src="talonsystemsimages/etaextras.webp" rounded /><p className="text-start">  
          <ListGroup>
            <ListGroup.Item>100 % Web Based</ListGroup.Item>
            <ListGroup.Item>All changes within ETA include the date of the change and the user who made it.</ListGroup.Item>
            <ListGroup.Item>Manage Multiple World-Wide Training Locations</ListGroup.Item>
            <ListGroup.Item>Complies with AICC and SCORM Standards</ListGroup.Item>
            <ListGroup.Item>Can be interfaced to other/legacy electronic systems (Maintenance, HR, Payroll, Accounting)</ListGroup.Item>
            <ListGroup.Item>User definable labels and lists</ListGroup.Item>
            <ListGroup.Item>ETA includes an internal messaging capability (Instant messaging)</ListGroup.Item>
            <ListGroup.Item>Operations provides special audio/visual cues to dispatchers for issues including aircraft assignment and overdue aircraft</ListGroup.Item>
            <ListGroup.Item>System administrators get real-time session information displayed for each current user with the ability to instant message that user!</ListGroup.Item>
            <ListGroup.Item>Electronic passenger manifests</ListGroup.Item>
            <ListGroup.Item>Cross-country flight following</ListGroup.Item>
            <ListGroup.Item>Flight Information File review and sign off</ListGroup.Item>
            <ListGroup.Item><p className="text-center"><a href="/talonetamobile" className="btn btn-primary btn-lg btn-block" role="button" aria-pressed="true">Learn about our Talon Mobile App</a></p></ListGroup.Item>
          </ListGroup>
          </p></div>
          <div className="col-md-4"><h2>Personnel Management</h2><Image src="talonsystemsimages/etapersonnelmanagement.webp" rounded /><p className="text-start">
        <ListGroup>
          <ListGroup.Item>All personnel biographic and demographic data</ListGroup.Item>
          <ListGroup.Item>Register students online</ListGroup.Item>
          <ListGroup.Item>Central capability for student management actions including (course registrations, assign contract rates, debit account transactions, no-show actions)</ListGroup.Item>
          <ListGroup.Item>Student information release authorizations</ListGroup.Item>
          <ListGroup.Item>Student training history</ListGroup.Item>
          <ListGroup.Item>Instructor flight experience history</ListGroup.Item>
          <ListGroup.Item>Tracking and management of instructor groundings</ListGroup.Item>
          <ListGroup.Item>Quick access to summary of student training progression and status</ListGroup.Item>
          <ListGroup.Item>Tracking and management of student holds and groundings</ListGroup.Item>
          <ListGroup.Item>Emergency contact information immediately available</ListGroup.Item>
          <ListGroup.Item>Track rental customer qualifications and currencies</ListGroup.Item>
          <ListGroup.Item>Current pilot log data for all flying personnel</ListGroup.Item>
          <ListGroup.Item>Student no-show records and summaries</ListGroup.Item>
        </ListGroup>
        </p></div>

        <div className="col-md-4"><h2>Security</h2><Image src="talonsystemsimages/etasecurity.webp" rounded /><p className="text-start">
        Every user in ETA is assigned a User Type. This allows managers to grant individuals access to specific pages in ETA while restricting access to other areas of the system. User types are 
        user-defined. A manager can group users into logical types such as students, instructors, admin, cashier, ops desk, etc.. After defining the user types, ETA pages are assigned, with different 
        levels of access (No access, Read only access, Limited Read access, and Read/Write access). Roles can also be assigned to specific individuals for approval authority to accomplish various ETA 
        managerial functions.
        </p></div>



        </div>

        <div data-aos-delay="500" data-aos="fade-up-left" className="row boxes">
          <div className="col-md-4"><h2>Training Resources</h2><Image src="talonsystemsimages/etatrainingresources.webp" rounded /><p className="text-start">  
          Track Aircraft, Simulators and more: Optimized fleet utilization by tracking maintenance events and using the time remaining for prioritizing aircraft.
          </p></div>

          <div className="col-md-4"><h2>Customizable</h2><Image src="talonsystemsimages/etacustomizable.webp" rounded /><p className="text-start">ETA can be customized by the user for unique customer requirements!
          The definitions component provides the capability to define labels, pick lists or drop down menus, location operating rules and parameters such as date and time thresholds or durations, and much more. Customers with multiple locations can configure the ETA system with different and unique system operating capabilities for each location.
          <p className="text-center"><a href="/talonetacustomizable" className="btn btn-primary btn-lg btn-block" role="button" aria-pressed="true">Learn more about Customization!</a></p>
        </p></div>

        <div className="col-md-4"><h2>Reports</h2><Image src="talonsystemsimages/etareports.webp" rounded />
        <p className="text-start">
        We have just under 300 reports that will help you get all the key information you need to run your flight school complete with an automated report delivery so you can be fully prepared for the day!
        </p>
        
        <p className="text-center"><a href="/talonetareports" className="btn btn-primary btn-lg btn-block" role="button" aria-pressed="true">Learn more about TalonETA Reports!</a></p>
        </div>
        </div>




        </Container>
        <Footer />
        </div>
    )
  }
  
  export default TalonETAPage;