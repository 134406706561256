import React, { useEffect } from 'react';
import { Container, Row, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import "../css/Navigation.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import Image from 'react-bootstrap/Image';
import AOS from 'aos';
import "aos/dist/aos.css";

const Navigation = () => {

  useEffect(() => {
    AOS.init ({ duration: 2000 });
}, []);

  return (
    <div>
    <Container>
    <Row>
    <div className="col-md-12 align-middle mb-5">
      <Navbar.Brand href="/">
        <Image data-aos="fade-down" className="customcenter m-auto" src="talonsystemsimages/talonLogo.png" rounded />
      </Navbar.Brand> 

      <Navbar data-aos="fade-up" expand="lg">
        <Navbar.Toggle aria-controls="navbarNav" />
        <Navbar.Collapse id="navbarNav">
          <Nav variant="underline" className="mx-auto">
            <LinkContainer to="/">
              <Nav.Link>Home</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/aboutus">
              <Nav.Link>About Us</Nav.Link>
            </LinkContainer>
            <NavDropdown className="dropend" title="Our Products" id="basic-nav-dropdown">
                <NavDropdown.Item href="taloneta">TalonETA</NavDropdown.Item>
                <NavDropdown.Item href="talonrms">TalonRMS</NavDropdown.Item>
                <NavDropdown.Item href="talonsmart">TalonSMART</NavDropdown.Item>
            </NavDropdown>
            <LinkContainer to="/faq">
              <Nav.Link>FAQ</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/testimonials">
              <Nav.Link>Testimonials</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/contactus">
              <Nav.Link>Contact Us</Nav.Link>
            </LinkContainer>
          </Nav>
            </Navbar.Collapse>
      </Navbar>

      </div>
      </Row>


{/* Mobile Version Nav */}

        {/* <Row>
      <div className="col-md-12 mb-3 align-middle d-lg-none">



      <Navbar expand="lg">
        <Navbar.Toggle aria-controls="navbarNav" />
        <Navbar.Collapse id="navbarNav">
          <Nav variant="underline" className="mx-auto">
            <LinkContainer to="/">
              <Nav.Link>Home2</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/aboutus">
              <Nav.Link>About Us</Nav.Link>
            </LinkContainer>
            <NavDropdown  title="Our Products" id="basic-nav-dropdown">
                <NavDropdown.Item href="taloneta">TalonETA</NavDropdown.Item>
                <NavDropdown.Item href="talonrms">TalonRMS</NavDropdown.Item>
                <NavDropdown.Item href="talonsmart">TalonSMART</NavDropdown.Item>
            </NavDropdown>
            <LinkContainer to="/faq">
              <Nav.Link>FAQ</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/testimonials">
              <Nav.Link>Testimonials</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/contactus">
              <Nav.Link>Contact Us</Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Navbar>


      
      </div>
      </Row> */}
      </Container>
   </div>
  );
}

export default Navigation;