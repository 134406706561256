import React from "react";
import Navigation from "./navigation";
import { Row, Container} from "react-bootstrap"
import "../css/Errorhandler.css"
import Footer from '../footer';
// import Button from 'react-bootstrap/Button';
// import Form from 'react-bootstrap/Form';
// import InputGroup from 'react-bootstrap/InputGroup';
// import Infobar from '../infobar';

function Error404() {

  return (
    <div>
    <Navigation />
    <Container>

    <Row className="mb-5 mt-3"></Row>

        <div className="col-md-12 mb-3 mx-auto"><div>
      <div>
        <div className="trans">
           <h1 className="errorheader">Error 404! Page Not Found!</h1>

          <p>Sorry for the inconvenience. The page you attempted to access has been moved or deleted.</p>
        </div>
        <div>
        </div>
      </div>
    </div> 
    </div>
        

    </Container>
    <Footer />
    </div>
  );
}

export default Error404;