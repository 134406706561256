import React, { useEffect } from 'react';
import "../css/TalonRMS.css"
import Navigation from "./navigation";
import Footer from '../footer';
import { Row, Container} from "react-bootstrap"
import Image from 'react-bootstrap/Image';
import ListGroup from 'react-bootstrap/ListGroup';
import Infobar from '../infobar';
import AOS from 'aos';
import "aos/dist/aos.css";


function TalonRMSReports() {
  useEffect(() => {
    AOS.init ({ duration: 1000 });
    document.title = 'RMS Reports - Talon Systems';
}, []);
    return (
        <div>
        <Infobar />
        <Navigation />
        <Container>

        <Row className="mb-3 mt-3"></Row>
  
        <div data-aos="fade" className="row boxes">
        <div className="col-md-4 mb-3 mt-3 d-none d-lg-block"><hr /></div>
        <div className="col-md-4 mb-3 mt-3 d-none d-lg-block"><h1>RMS Reports</h1></div>
        <div className="col-md-4 mb-3 mt-3 d-none d-lg-block"><hr /></div>
        <div className="col-md-12 mb-3 mt-3 d-lg-none"><h1>RMS Reports</h1></div> 

        <div className="col-md-3 mb-3"></div>
        <div className="col-md-6 mb-3"><Image src="talonsystemsimages/rmsreport.webp" rounded /></div>
        <div className="col-md-3 mb-3"></div>

        <div className="col-12 mb-3"><h3>This is a small list of TalonRMS Reporting Capabilities. <br /> Complete with Optional Automated Email Report Delivery. </h3></div>
        </div>

        <div className="row boxes">
        <div data-aos="fade-right" className="col-6 mb-3 ">
          <ListGroup>
          <ListGroup.Item>Aircraft Engine \ Propeller Hours per Month - Number of hours per aircraft engine and propeller per month</ListGroup.Item>
        <ListGroup.Item>Aircraft Hours per Month - Number of Frame, Hobbs and Tach hours per aircraft per month</ListGroup.Item>
        <ListGroup.Item>Aircraft Status Sheet - Summary of aircraft events and discrepancies with the option to use Tach Time instead of airframe time</ListGroup.Item>
        <ListGroup.Item>ATA Code by Resource Summary - Summary of ATA Codes by resource with individual and total code count.</ListGroup.Item>
        <ListGroup.Item>ATA Code by Resource Type Summary - List of ATA codes assigned to each resource type</ListGroup.Item>
        <ListGroup.Item>ATA Codes Summary - List of ATA codes</ListGroup.Item>
        <ListGroup.Item>Backordered Parts - List of backordered parts</ListGroup.Item>
        <ListGroup.Item>Cashier Invoice Summary - List of work order invoices with totals for labor, parts, miscellaneous, tax over a date range</ListGroup.Item>
        <ListGroup.Item>Cashier Maintenance Profits - List of work order invoices using the difference between charges and costs for labor and parts to determine invoice profit</ListGroup.Item>
        <ListGroup.Item>Certifications Expiring - List of Currencies with names of personnel that are within X days of expiration</ListGroup.Item>
        <ListGroup.Item>Certifications Unassigned - List of personnel missing certifications</ListGroup.Item>
        <ListGroup.Item>Contracts to Resource XRef - List of resources assigned to each contract</ListGroup.Item>
        <ListGroup.Item>Customer Debit Account Balance - Debit account balances per customer</ListGroup.Item>
        <ListGroup.Item>Customer Debit Account Transactions - List of debit account transactions</ListGroup.Item>
        <ListGroup.Item>Customer Invoice Report - Invoices for a customer over a date range</ListGroup.Item>
        <ListGroup.Item>Customer List Summary - List of customers with primary contact, # resources, # open work orders and # open invoices</ListGroup.Item>
        <ListGroup.Item>Customer Resources Summary - List of resources per customer with times, # open work orders and # open invoices</ListGroup.Item>
        <ListGroup.Item>Customer Work Order Report - Work orders for a customer over a date range</ListGroup.Item>
        <ListGroup.Item>Engine / Propeller Due Time - List of aircraft that have an engine and/or propeller within X hours of TBO</ListGroup.Item>
        <ListGroup.Item>Engine / Propeller Overhaul Due - List of aircraft that have an engine and/or propeller overhaul due within a specified number of hours or days</ListGroup.Item>
        <ListGroup.Item>Engine / Propeller Status - Engine / propeller details per aircraft</ListGroup.Item>
        <ListGroup.Item>Engine / Propeller Times Used - List of aircraft with engine / propeller total time and TSMOH</ListGroup.Item>
        <ListGroup.Item>Events Audit - List of resources grouped by event including time remaining, due, overdue</ListGroup.Item>
        <ListGroup.Item>Expiring Inventory - List of parts that expire within X months</ListGroup.Item>
        <ListGroup.Item>Fleet - List of aircraft resources</ListGroup.Item>
        <ListGroup.Item>Generic Work Order Time - Time recorded per person against generic work orders opened within a date range</ListGroup.Item>
        <ListGroup.Item>Inventory by Location - List of parts with quantities per inventory bin and inventory location</ListGroup.Item>
        <ListGroup.Item>Inventory Count Report - List of parts with quantity per bin used for performing an inventory quantity audit</ListGroup.Item>
        <ListGroup.Item>Inventory Open Order Parts Count - List of parts currently on order</ListGroup.Item>
        <ListGroup.Item>Inventory Open Repair and Returns - Lists repair and return parts (from shipping) that are still out for repair and/or have not been received</ListGroup.Item>
        <ListGroup.Item>Inventory Part Quantities - Min Quantity, Max Quantity, Current Quantity, Quantity on Order, Quantity Issued to Work Orders per part</ListGroup.Item>
        <ListGroup.Item>Inventory Parts Added to Stock - Parts added to inventory over a date range including quantity and cost</ListGroup.Item>
        <ListGroup.Item>Inventory Parts by Part Type - List of parts with associated cost and inventory bin grouped by part type (Estimated cost for non-serial stock)</ListGroup.Item>
        <ListGroup.Item>Inventory Parts Core Cost Audit - List of core cost and part cost for stocked parts</ListGroup.Item>
        <ListGroup.Item>Inventory Parts Cost Audit - List of parts per inventory location with quantity and cost information</ListGroup.Item>
        <ListGroup.Item>Inventory Parts Disposed - List of parts disposed of within a date range</ListGroup.Item>
        <ListGroup.Item>Inventory Parts Order Count By Date - List of parts ordered over a date range including order status</ListGroup.Item>
        <ListGroup.Item>Inventory Parts Removed - List of parts that have been removed from resources within a date range</ListGroup.Item>
        <ListGroup.Item>Inventory Parts Reorder Report - List of parts that have a stock quantity (from issuing locations) equal to or less than the part Reorder Quantity</ListGroup.Item>
        <ListGroup.Item>Inventory Parts TSMOH Remaining - List of stock with the amount of time remaining until overhaul</ListGroup.Item>
        <ListGroup.Item>Inventory PO Dates - List of POs over a date range with date ordered, due date and received date</ListGroup.Item>
        <ListGroup.Item>Inventory POs Generated - List of purchase orders entered within a date range</ListGroup.Item>
        <ListGroup.Item>Inventory POs Generated Cost Analysis - List of purchase orders entered within a date range including current stock, quantity, and status</ListGroup.Item>
        <ListGroup.Item>Inventory POs Invoiced - List of part orders received with the date invoiced and invoice #</ListGroup.Item>
        <ListGroup.Item>Inventory POs Received - List of purchase orders received within a date range</ListGroup.Item>
        <ListGroup.Item>Inventory POs Received Cost Analysis - List of purchase orders received within a date range including current stock, quantity, and status</ListGroup.Item>

          </ListGroup>
        </div>

        <div data-aos="fade-left" className="col-6 mb-3">
        <ListGroup>
        <ListGroup.Item>Inventory Shipped Parts - Lists part shipments within a date range</ListGroup.Item>
        <ListGroup.Item>Inventory Transaction History - List of inventory transactions over a date range</ListGroup.Item>
        <ListGroup.Item>Inventory Work Order Parts Cost Summary - List of resources with cost of parts used over a date range</ListGroup.Item>
        <ListGroup.Item>Inventory Work Order Parts Details - List of parts used per work order with associated part cost</ListGroup.Item>
        <ListGroup.Item>Management Tech Time - List of maintenance personnel with total time clocked over a date range</ListGroup.Item>
        <ListGroup.Item>Management Tech Time per Site - List of maintenance personnel with total time clocked over a date range per site</ListGroup.Item>
        <ListGroup.Item>Out of Stock Serialized Items - List of serialized parts with zero quantity in stock</ListGroup.Item>
        <ListGroup.Item>Personnel Certifications - List of personnel per certification</ListGroup.Item>
        <ListGroup.Item>Personnel Maintenance Details - List of maintenance personnel</ListGroup.Item>
        <ListGroup.Item>Personnel Training per Person - List of Training per person</ListGroup.Item>
        <ListGroup.Item>Resource Activity Count per Date - Daily summary including number of activities, total activity duration, discrepancy time and airframe total</ListGroup.Item>
        <ListGroup.Item>Resource Aircraft Next Time Event - List of aircraft with next tach event</ListGroup.Item>
        <ListGroup.Item>Resource Aircraft Times - List of aircraft with engine / propeller total time and TSMOH</ListGroup.Item>
        <ListGroup.Item>Resource Aircraft Total Times - List of aircraft with current airframe times and tach times</ListGroup.Item>
        <ListGroup.Item>Resource Date Events Due - List of resource date events coming due within X days per resource</ListGroup.Item>
        <ListGroup.Item>Resource Discrepancies and Corrective Actions - List of resource discrepancies and corrective actions with current status over a date range</ListGroup.Item>
        <ListGroup.Item>Resource Discrepancy Status - Returns a list of discrepancies entered within a date range per resource</ListGroup.Item>
        <ListGroup.Item>Resource Down History - Displays history of the downs per resource over a date range</ListGroup.Item>
        <ListGroup.Item>Resource Event Status - List of resource events by time/date with time/days remaining</ListGroup.Item>
        <ListGroup.Item>Resource Events List - List of events with filter options on resource type, event type and time remaining</ListGroup.Item>
        <ListGroup.Item>Resource Hour Events Due - List of resource hour events coming due within X hours per resource</ListGroup.Item>
        <ListGroup.Item>Resource Misc Event Due - List of resource misc events coming due within X per resource</ListGroup.Item>
        <ListGroup.Item>Resource Miscellaneous Value History - List of miscellaneous value changes per resource over a date range</ListGroup.Item>
        <ListGroup.Item>Resource Parts - List of parts per resource</ListGroup.Item>
        <ListGroup.Item>Resource Priority Flow - List of resources ordered by priority</ListGroup.Item>
        <ListGroup.Item>Resource Status History - The amount of time a resource has accumulated within each resource status over a date range</ListGroup.Item>
        <ListGroup.Item>Resource Time Discrepancies - List of resource time changes (Airframe, Tach, Hobbs) made during a date range</ListGroup.Item>
        <ListGroup.Item>Resource Time History - List of airframe, tach, and hobbs for a resource over a date range</ListGroup.Item>
        <ListGroup.Item>Resource W/O Time Totals - List of work orders with total clocked time per resource</ListGroup.Item>
        <ListGroup.Item>Resource Weight and Balance - List of aircraft with weight and balance information grouped by aircraft type</ListGroup.Item>
        <ListGroup.Item>Resource Work - List of work per resource</ListGroup.Item>
        <ListGroup.Item>Resource Work Orders - Print all the work orders for a specific resource over a date range</ListGroup.Item>
        <ListGroup.Item>Resources Downed by Reason - List of resource downs over a date range including down duration and reason</ListGroup.Item>
        <ListGroup.Item>Tools Due for Calibration - List of tools with calibration tests due within the next X days</ListGroup.Item>
        <ListGroup.Item>Total Labor Hours - Total hours clocked per resource over a date range</ListGroup.Item>
        <ListGroup.Item>Total Labor Hours per Site - Total hours clocked per resource over a date range</ListGroup.Item>
        <ListGroup.Item>Total Labor Hours With Cost - Total hours and associated labor costs clocked on work orders per resource over a date range</ListGroup.Item>
        <ListGroup.Item>Total Labor Hours with Costs per Site - Total hours and associated labor costs clocked on work orders per resource over a date range per site</ListGroup.Item>
        <ListGroup.Item>Trial Balance - List of transactions over a date range</ListGroup.Item>
        <ListGroup.Item>Warranty - List of part warranties</ListGroup.Item>
        <ListGroup.Item>Work ATA Codes - Returns work performed with associated ATA Code</ListGroup.Item>
        <ListGroup.Item>Work Item and Labor Details - Details of work item and labor including item description, tech name and time.</ListGroup.Item>
        <ListGroup.Item>Work Order Open \ Close Dates - Returns who \ when opened and closed each work order over a date range, also includes number of discrepancies and number of events</ListGroup.Item>
        <ListGroup.Item>Work Order Parts Cost Audit - List of parts used over a date range</ListGroup.Item>
        <ListGroup.Item>Work Order Workflow - Displays the workflow of a work order</ListGroup.Item>
        <ListGroup.Item>Work Orders - Labor Cost per Site - List of work orders with total labor time and cost per maintenance personnel, over a date range per Site</ListGroup.Item>
        <ListGroup.Item>Work Orders Labor Costs - List of work orders with total labor time and cost per maintenance personnel, over a date range</ListGroup.Item>
        </ListGroup>
        </div>
        </div>

        </Container>
        <Footer />
        </div>

    )
  }
  
  export default TalonRMSReports;

